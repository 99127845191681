import { useCallback, CSSProperties, Dispatch, SetStateAction } from 'react';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuTrigger,
} from '@/components/ui/context-menu';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@/components/ui/tooltip';
import { dropStyle, hoverStyles, selectedStyles, styles } from '@/components/sidebar/sidebar';
import { useDraggable, useDndMonitor, useDroppable } from '@dnd-kit/core';
import { NodeType, TreeRecursiveChildrenNode } from '@/types/tree';
import { BookImage, Pen, Trash2Icon, Users } from 'lucide-react';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { useCommandContext } from '@/context/CommandContext';
import { AddAssetCommand } from '@/hooks/commands/albums/AddAssetCommand';
import { DraggableItems } from '@/types/dragDrop';
import { useAlbumAssets } from '@/hooks/data/albums/useAlbumAssets';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { useTreeStore } from '@/hooks/data/tree/useTreeStore';
import { ToastAction } from '@/components/ui/toast';
import { useDialog } from '@/context/DialogContext';
import { useRouter } from 'next/router';
import { useToast } from '@/components/ui/use-toast';
import { useTree } from '@/hooks/data/tree/useTree';
import { CSS } from '@dnd-kit/utilities';
import { cn } from '@/lib/utils';
import { SortOrderValues } from '@/types/sort';
import { PaginationState } from '@/types/pagination';

type AlbumProps = {
  treeBranch: TreeRecursiveChildrenNode;
  parentId?: string;
  depth: number;
  handleOnSortChange: (value: string) => void;
  setPagination?: Dispatch<SetStateAction<PaginationState>>;
};

export function Album({ treeBranch, parentId, depth, handleOnSortChange, setPagination }: AlbumProps) {
  const findNodePath = useTreeStore((state) => state.findNodePath);
  const setSelectedFolder = useTreeStore((state) => state.setSelectedFolder);
  const setSelectedAlbum = useTreeStore((state) => state.setSelectedAlbum);

  const { browseRedirection } = useCurrentPage();
  const { openModal } = useDialog();
  const { id, node, shared } = treeBranch;
  const { name, id: albumId } = node;
  const { query, push } = useRouter();
  const { setSelectedNodes, setCurrentSelectedType, selectedAlbum } = useTree();
  const { selectedAssetIds, deselectAllAssets } = useAssetSelection();
  const { addAssets, removeAssets } = useAlbumAssets();
  const { apply } = useCommandContext();
  const { toast } = useToast();

  const onSelectAlbum = useCallback(() => {
    deselectAllAssets();
    setSelectedNodes([{ id, name }]);
    setCurrentSelectedType(NodeType.Albums);

    const pathArray = findNodePath(id);

    setSelectedFolder(undefined);
    setSelectedAlbum(id);

    handleOnSortChange(SortOrderValues.CREATED_AT);
    if (setPagination) {
      setPagination((prev) => ({
        ...prev,
        pageIndex: 0,
      }));
    }

    browseRedirection(pathArray);
  }, [
    id,
    node.id,
    push,
    query,
    selectedAlbum,
    setCurrentSelectedType,
    setSelectedNodes,
    handleOnSortChange,
    setPagination,
  ]);

  const { setNodeRef, isOver } = useDroppable({
    id: albumId,
    data: {
      type: DraggableItems.ALBUM,
      id: albumId,
      parentId: parentId,
      accepts: [DraggableItems.ASSET],
    },
  });

  const {
    attributes,
    listeners,
    setNodeRef: setDragRef,
    transform,
    isDragging,
  } = useDraggable({
    id,
    data: { type: DraggableItems.ALBUM, id, name, parentId },
  });

  useDndMonitor({
    onDragEnd(event) {
      void (async () => {
        const { active, over } = event;
        if (
          over?.id === albumId &&
          over.data.current?.accepts.includes(active.data.current?.type) &&
          over?.data?.current?.type === DraggableItems.ALBUM &&
          active?.data?.current?.type === DraggableItems.ASSET
        ) {
          let assetIds = [active.data?.current?.id];
          if (active.data?.current?.type === DraggableItems.ASSET && selectedAssetIds?.length > 1) {
            assetIds = selectedAssetIds.map((asset) => asset.id);
          }
          const addAssetCommand = AddAssetCommand(addAssets, removeAssets, node.id, assetIds);
          const response = await apply(addAssetCommand);
          const assetsAdded = response.add ?? assetIds;
          const wereAssetsAdded = assetsAdded.length > 0;
          if (active.data?.current?.type === DraggableItems.ASSET) {
            toast({
              title: wereAssetsAdded ? 'Asset(s) added' : 'No asset has been added',
              description: wereAssetsAdded
                ? `Asset(s) have been added successfully.`
                : 'The album already contains the asset(s).',
              action: wereAssetsAdded ? (
                <ToastAction
                  onClick={() => removeAssets({ albumId: node.id, assetIds: assetsAdded })}
                  altText="Undo add asset(s)"
                >
                  Undo
                  <ArrowUturnLeftIcon className="ml-1 size-3" />
                </ToastAction>
              ) : undefined,
            });
            deselectAllAssets();
          }
        }
      })();
    },
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  };

  return (
    <ContextMenu>
      <ContextMenuTrigger className="flex-1">
        <li
          key={id}
          className={cn('mt-px flex flex-col', isDragging && 'rounded-md border border-dashed border-separator-color')}
          title={name}
        >
          <div
            style={{ paddingLeft: `${depth * 15}px`, ...style }}
            ref={setNodeRef}
            className={cn(
              'flex w-full items-center rounded-md pr-1.5',
              styles,
              hoverStyles,
              selectedAlbum === node.id && selectedStyles,
              isOver && dropStyle,
            )}
          >
            <button
              onClick={() => onSelectAlbum()}
              onContextMenu={() => onSelectAlbum()}
              className={cn('flex w-full items-center justify-start py-1 pl-6 outline-none')}
              ref={setDragRef}
              {...listeners}
              {...attributes}
            >
              <BookImage className="mr-2 inline-block size-4 min-w-4 stroke-teal-600" strokeWidth={2} />
              <div className="flex w-full justify-between text-sm">
                <span className="line-clamp-1 break-all text-left">{name}</span>
                {/* placeholder for items counter */}
                {/* <Badge
                  className={cn(
                    'ml-2 whitespace-nowrap rounded-full px-2 py-0 text-[11px]',
                    selectedFolder === albumId
                      ? 'bg-neutral-100 dark:bg-neutral-900'
                      : 'bg-neutral-200 dark:bg-neutral-800',
                  )}
                  variant="secondary"
                >
                  {items.count}
                </Badge> */}
              </div>
              {shared && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Users className="inline-block size-4 min-w-4" strokeWidth={2} />
                  </TooltipTrigger>
                  <TooltipPortal>
                    <TooltipContent>This is a shared album</TooltipContent>
                  </TooltipPortal>
                </Tooltip>
              )}
            </button>
          </div>
        </li>
      </ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuItem onClick={() => openModal('editCreateFolderAlbum', 'renameAlbum', { id: node.id })}>
          <Pen className="mr-2 size-4" />
          Rename
        </ContextMenuItem>
        <ContextMenuSeparator />
        <ContextMenuItem
          onClick={() => {
            onSelectAlbum();
            openModal('deleteConfirmation');
          }}
          className="text-red-600 focus:bg-red-100 focus:text-red-600"
        >
          <Trash2Icon className="mr-2 size-4" />
          Delete
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
}
