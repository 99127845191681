import { CSSProperties, useEffect, useMemo } from 'react';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuTrigger,
} from '@/components/ui/context-menu';
import { SortableContext, verticalListSortingStrategy, useSortable, rectSortingStrategy } from '@dnd-kit/sortable';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Row, flexRender, useReactTable, VisibilityState } from '@tanstack/react-table';
import { FolderInput, Pen, Tag, Trash2Icon, X } from 'lucide-react';
import { SelectionEventProps } from '@/components/layouts/gallery-layout';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { UniqueIdentifier } from '@dnd-kit/core';
import { DuplicatedAsset } from '@/types/duplicates';
import { gridColsLookup } from '@/context/UserSettingsContext';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { DraggableItems } from '@/types/dragDrop';
import { GalleryView } from '@/types';
import { useDialog } from '@/context/DialogContext';
import { NodeType } from '@/types/tree';
import { useTree } from '@/hooks/data/tree/useTree';
import { Asset } from '@/types/asset';
import { CSS } from '@dnd-kit/utilities';
import { cn } from '@/lib/utils';
import { DragOverlay } from '@dnd-kit/core';
import { DraggedItem } from '@/components/data-table/data-table-dragged-item';
import { useDragAndDrop } from '@/context/DragAndDropContext';
import { useUploadStore } from '@/hooks/data/inspector/useUploadStore';
import { UploadState } from '@/types/uploads';
import { Button } from '../ui/button';

interface DataTableProps<TData extends Asset | DuplicatedAsset, TValue> {
  parentNodeId?: string;
  data: Array<TData>;
  galleryView?: GalleryView;
  assetSize: Array<number>;
  columnVisibility?: VisibilityState;
  table: ReturnType<typeof useReactTable<TData>>;
  selectedAssetIds: Array<{ id: string; name: string }>;
  dataIds: Array<UniqueIdentifier>;
  handleClick?: (event: SelectionEventProps, row: Row<TData>) => void;
  handleRightClick?: (asset: TData) => void;
  handleDoubleClick: (id: string) => Promise<boolean>;
  isListView: boolean;
}

export const DraggableItem = <TData extends Asset>({
  row,
  assets,
  parentNodeId,
  handleClick,
  handleDoubleClick,
  handleRightClick,
  isRow,
}: {
  row: Row<TData>;
  assets: Array<TData>;
  parentNodeId?: string;
  columnVisibility?: VisibilityState;
  handleClick?: (event: SelectionEventProps, row: Row<TData>) => void;
  handleDoubleClick: (id: string) => void;
  handleRightClick?: (asset: TData) => void;
  isRow?: boolean;
}) => {
  const { toggleNodeSelection, setCurrentSelectedType } = useTree();
  const { toggleAssetSelection, selectedAssetIds, setSelectedAssetIds } = useAssetSelection();
  const { openModal } = useDialog();
  const { isPublicRoute, isTrashPage, isSearchPage, isUploadPage } = useCurrentPage();
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: row.original.id,
    data: {
      id: row.original.id,
      type: DraggableItems.ASSET,
      asset: row.original,
      parentNodeId: parentNodeId,
      accepts: DraggableItems.ASSET,
    },
    disabled: Boolean(isPublicRoute || isTrashPage || isSearchPage),
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    opacity: isDragging ? 0 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  };

  const isSelected = row.getIsSelected();
  const { removeAssets, uploadState } = useUploadStore();

  useEffect(() => {
    if (isDragging && !isSelected) {
      toggleAssetSelection({
        id: row.original.id,
        name: row.original.name,
        assets,
        index: row.index,
      });

      toggleNodeSelection({
        id: row.original.nodeId,
        name: row.original.name,
        assets,
        index: row.index,
      });

      setCurrentSelectedType(NodeType.Assets);
    }
  }, [
    isDragging,
    isSelected,
    row,
    assets,
    toggleAssetSelection,
    toggleNodeSelection,
    setCurrentSelectedType,
    setSelectedAssetIds,
  ]);

  const groupedColumns = {
    thumbnail: { keys: ['thumbnail'], className: '' },
    nameAndType: { keys: ['name', 'type'], className: 'mb-px mt-1 flex w-full items-center gap-4' },
    fileDetails: { keys: ['fileName', 'size'], className: 'flex w-full gap-4' },
    other: { keys: ['description', 'slug', 'nodeId', 'progress'], className: '' },
  };

  return isRow ? (
    <TableRow
      ref={setNodeRef}
      {...(isSelected && { 'data-state': 'selected' })}
      {...(handleDoubleClick && { onDoubleClick: () => handleDoubleClick(row.original.id) })}
      style={style}
      {...attributes}
      {...listeners}
    >
      {row
        .getVisibleCells()
        .map((cell: { id: string; column: { columnDef: any; getSize: () => number }; getContext: () => any }) => {
          return (
            <TableCell
              key={cell.id}
              style={{ width: cell.column.getSize() !== 150 ? cell.column.getSize() : 'auto' }}
              className={cn('select-none', cell.column.columnDef.meta?.cellClassName)}
              onClick={(event) => handleClick?.(event, row)}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          );
        })}
    </TableRow>
  ) : // Excluding right click context menu for public route
  isPublicRoute ? (
    <div
      {...(isSelected && { 'data-state': 'selected' })}
      {...(handleDoubleClick && { onDoubleClick: () => handleDoubleClick(row.original.id) })}
      onClick={(event) => handleClick?.(event, row)}
      className={cn('relative p-3 transition-colors duration-150', isSelected && 'h-full bg-light-ui-color')}
    >
      {Object.entries(groupedColumns).map(([group, { keys, className }]) => (
        <div key={group} className={className}>
          {row
            .getVisibleCells()
            .filter((cell) => keys.includes((cell.column.columnDef as { accessorKey: string }).accessorKey))
            .map((cell) => flexRender(cell.column.columnDef.cell, cell.getContext()))}
        </div>
      ))}
    </div>
  ) : (
    <ContextMenu key={row.original.id}>
      <ContextMenuTrigger>
        <div
          {...(isSelected && { 'data-state': 'selected' })}
          ref={setNodeRef}
          onContextMenu={() => handleRightClick && handleRightClick(assets[row.index])}
          {...(handleDoubleClick && { onDoubleClick: () => handleDoubleClick(row.original.id) })}
          onClick={(event) => handleClick?.(event, row)}
          style={style}
          {...attributes}
          {...listeners}
          className={cn('h-full p-3 transition-colors duration-150', isSelected && 'bg-light-ui-color')}
        >
          {Object.entries(groupedColumns).map(([group, { keys, className }]) => (
            <div key={group} className={className}>
              {row
                .getVisibleCells()
                .filter((cell) => keys.includes((cell.column.columnDef as { accessorKey: string }).accessorKey))
                .map((cell) => flexRender(cell.column.columnDef.cell, cell.getContext()))}
            </div>
          ))}
        </div>
      </ContextMenuTrigger>
      <ContextMenuContent className="w-40">
        <ContextMenuItem disabled>
          <Pen className="mr-2 size-4" />
          Edit asset
        </ContextMenuItem>
        <ContextMenuItem disabled>
          <Tag className="mr-2 size-4" />
          Edit Keywords
        </ContextMenuItem>
        <ContextMenuSeparator />
        <ContextMenuItem
          disabled={uploadState !== UploadState.NotStarted}
          onClick={() => (isUploadPage ? removeAssets() : openModal('deleteConfirmation'))}
          className="text-red-600 focus:bg-red-100 focus:text-red-600"
        >
          <Trash2Icon className="mr-2 size-4" />
          Remove Asset{selectedAssetIds.length > 1 ? 's' : ''}
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
};

export function DataTableGridListView<TData extends Asset | DuplicatedAsset, TValue>({
  parentNodeId,
  galleryView,
  assetSize,
  columnVisibility,
  table,
  data,
  dataIds,
  handleClick,
  handleDoubleClick,
  handleRightClick,
  isListView,
  selectedAssetIds,
}: DataTableProps<TData, TValue>) {
  const { activeItem } = useDragAndDrop();
  const { isSearchPage, isUploadPage } = useCurrentPage();

  const { removeFolder, uploadState } = useUploadStore();

  const selectedRows = useMemo(() => {
    if (activeItem && selectedAssetIds) {
      const rows = table.getRowModel().rows.filter((row) => selectedAssetIds.map((asset) => asset.id).includes(row.id));
      const firstRow = rows.find((row) => row.id === activeItem.id);
      const otherRows = rows.filter((row) => row.id !== activeItem.id);
      return firstRow ? [firstRow, ...otherRows.slice(0, 4)] : otherRows.slice(0, 5);
    }
  }, [activeItem, table, selectedAssetIds]);

  const outerContainerClass = table.getRowModel().rows.some((row) => row.getIsGrouped())
    ? // TODO: Remove max-h-[calc(100vh-101px)] once the layout is fixed
      'flex max-h-[calc(100vh-101px)] flex-col'
    : `relative grid max-h-[calc(100vh-133px)] p-6 ${gridColsLookup[assetSize[0]] || 'grid-cols-7'} ${
        isSearchPage && 'mt-32'
      }`;

  return galleryView === GalleryView.LIST ? (
    // TODO: Remove max-h-[calc(100vh-101px)] once the layout is fixed
    <div className="flex w-full overflow-hidden">
      <Table>
        <TableHeader className="rounded-none">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead
                  key={header.id}
                  className={cn((header.column.columnDef.meta as { headerClassName: string })?.headerClassName)}
                  style={{ width: header.getSize() !== 150 ? header.getSize() : 'auto' }}
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
            {table.getRowModel().rows.map((row) =>
              row.getIsGrouped() ? (
                <>
                  <tr key={row.id} className={cn(isUploadPage ? 'h-12 bg-panel-background' : 'h-10')}>
                    <td colSpan={table.getAllColumns().length}>
                      {isUploadPage ? (
                        <div className="flex min-h-12 items-center justify-between bg-panel-background pl-8 pr-3 text-base">
                          <div className="flex gap-x-3">
                            <FolderInput className="size-6 stroke-gray-icon-color" strokeWidth={2} />
                            <div className="flex select-none gap-x-1 font-light">
                              Upload to: <div className="font-bold">{row.renderValue('path')}</div>
                            </div>
                          </div>
                          <Button
                            variant="ghost"
                            disabled={uploadState !== UploadState.NotStarted}
                            onClick={() => removeFolder(row.renderValue('path'))}
                          >
                            <X className="size-6 stroke-gray-icon-color" />
                          </Button>
                        </div>
                      ) : (
                        <hr className="h-px border-0 bg-ui-bevel" />
                      )}
                    </td>
                  </tr>
                  {row.getLeafRows().map((leafRow) => (
                    <DraggableItem
                      isRow
                      key={leafRow.id}
                      row={leafRow}
                      assets={data}
                      parentNodeId={parentNodeId}
                      handleClick={handleClick}
                      handleDoubleClick={handleDoubleClick}
                    />
                  ))}
                </>
              ) : (
                <DraggableItem
                  isRow
                  key={row.id}
                  row={row}
                  assets={data}
                  parentNodeId={parentNodeId}
                  handleClick={handleClick}
                  handleDoubleClick={handleDoubleClick}
                />
              ),
            )}
          </SortableContext>
          <DragOverlay>{activeItem && <DraggedItem isListView={isListView} rows={selectedRows} />}</DragOverlay>
        </TableBody>
      </Table>
    </div>
  ) : (
    <div className={cn('w-full overflow-y-auto', outerContainerClass)}>
      <SortableContext items={dataIds} strategy={rectSortingStrategy}>
        {table.getRowModel().rows.map((row) =>
          row.getIsGrouped() ? (
            <>
              {isUploadPage && (
                <div className="flex min-h-12 items-center justify-between bg-panel-background pl-8 pr-3 text-base">
                  <div className="flex gap-x-3">
                    <FolderInput className="size-6 stroke-gray-icon-color" strokeWidth={2} />
                    <div className="flex select-none gap-x-1 font-light">
                      Upload to: <div className="font-bold">{row.renderValue('path')}</div>
                    </div>
                  </div>
                  <Button
                    variant="ghost"
                    disabled={uploadState !== UploadState.NotStarted}
                    onClick={() => removeFolder(row.renderValue('path'))}
                  >
                    <X className="size-6 stroke-gray-icon-color" />
                  </Button>
                </div>
              )}
              <div
                key={row.id}
                className={cn(
                  'relative grid gap-3 p-6',
                  gridColsLookup[assetSize[0]] || 'grid-cols-7',
                  !isUploadPage && 'border-b border-ui-bevel last:border-b-0',
                )}
              >
                {row.getLeafRows().map((leafRow) => (
                  <DraggableItem
                    key={leafRow.id}
                    row={leafRow}
                    assets={data}
                    parentNodeId={parentNodeId}
                    columnVisibility={columnVisibility}
                    handleClick={handleClick}
                    handleDoubleClick={handleDoubleClick}
                    handleRightClick={handleRightClick}
                  />
                ))}
              </div>
            </>
          ) : (
            <DraggableItem
              key={row.id}
              row={row}
              assets={data}
              parentNodeId={parentNodeId}
              columnVisibility={columnVisibility}
              handleClick={handleClick}
              handleDoubleClick={handleDoubleClick}
              handleRightClick={handleRightClick}
            />
          ),
        )}
      </SortableContext>
      <DragOverlay>{activeItem && <DraggedItem isListView={isListView} rows={selectedRows} />}</DragOverlay>
    </div>
  );
}
