import { z } from 'zod';
export const paginationSchema = z.object({
  offset: z.number(),
  limit: z.number(),
  count: z.number(),
});

export type PageSizes = Array<number>;

export const pageSizeOptions: PageSizes = [25, 50, 100, 500, 1000];

export const defaultPaginationIndex = 0;
export const defaultPaginationSize = pageSizeOptions[0];
export type PaginationState = {
  pageIndex: number;
  pageSize: number;
};

export const defaultPagination = {
  pageIndex: defaultPaginationIndex,
  pageSize: defaultPaginationSize,
};

export const auditDefaultPageSize = 20;
