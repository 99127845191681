import { damRequest, INTERNAL_V1 } from '@/hooks/damRequest';
import { getDestinationsSchema, destinationArraySchema, destinationSchema } from '@/types/destination';

const BASE = `${INTERNAL_V1}/destinations`;

export const getDestinationList = async (
  params: {
    pagination: { limit: number | null; offset: number | null };
    sort: { order: string; value: string };
    queryString: string;
  },
  token?: string,
) => {
  const response = await damRequest(
    'GET',
    `${BASE}/?offset=${params.pagination?.offset ?? ''}&limit=${params.pagination?.limit ?? ''}&filterQuery=${
      params.queryString
    }&sortBy=${params.sort?.value}&sortOrder=${params.sort?.order}`,
    token,
  );

  return getDestinationsSchema.parse(response);
};

export const setDestination = async (
  { destinationIds, itemId, type }: { destinationIds?: Array<string> | null; itemId?: string; type?: string },
  token?: string,
) => {
  const response = await damRequest('PUT', `${INTERNAL_V1}/${type}/${itemId}/destinations`, token, {
    body: { destinations: destinationIds },
  });

  return destinationArraySchema.parse(response);
};

export const getDestination = async (id: string, token?: string) => {
  const response = await damRequest('GET', `${BASE}/${id}`, token);
  return destinationSchema.parse(response);
};

export const deleteDestination = ({ id }: { id: string }, token?: string) =>
  damRequest('DELETE', `${BASE}/${id}`, token);

export const updateDestination = async ({ id, body }: { id: string; body: object }, token?: string) => {
  const response = await damRequest('PATCH', `${BASE}/${id}`, token, { body });
  return destinationSchema.parse(response);
};

export const createDestination = async (body: object, token?: string) => {
  const response = await damRequest('POST', BASE, token, { body });
  return destinationSchema.parse(response);
};
