import { ElementType, FC, RefObject, useState } from 'react';
import Image from 'next/image';
import { File, FileText, FileImage, FileVideo, FileAudio, Play, LucideIcon } from 'lucide-react';
import { FileType } from '@/types/fileType';
import { cn } from '@/lib/utils';

export type AssetThumbnailProps = {
  name: string;
  fileType?: string;
  thumbnail?: string;
  iconSize: number;
  draggable?: boolean;
  width?: number;
  height?: number;
  imageRef?: RefObject<HTMLImageElement>;
  iconContainerRef?: RefObject<HTMLImageElement>;
  overlayIcon?: LucideIcon;
  iconStrokeWidth?: number;
  fill?: boolean;
  onLoad?: () => void;
  imageClassName?: string;
  iconClassName?: string;
  imageContainerClassName?: string;
  iconContainerClassName?: string;
  playIconClassName?: string;
  overlayIconClassName?: string;
  overlayIconSize?: number;
  onDoubleClick?: () => void;
  error?: boolean;
};

export const AssetThumbnail: FC<AssetThumbnailProps> = ({
  name,
  fileType,
  thumbnail,
  iconSize,
  onLoad,
  draggable = false,
  width,
  height,
  imageRef,
  iconContainerRef,
  overlayIcon: Icon,
  iconStrokeWidth = 1,
  fill = true,
  imageClassName = '',
  iconClassName = '',
  imageContainerClassName = 'relative',
  iconContainerClassName = '',
  playIconClassName = '',
  overlayIconClassName = '',
  onDoubleClick,
  error,
}) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const renderImage = (src: string) => (
    <div className={imageContainerClassName}>
      {fileType === FileType.Video && (
        <Play
          className={cn(
            'absolute z-10 stroke-white drop-shadow-[1px_1px_3px_hsla(0,0%,0%,0.25)]',
            playIconClassName ? playIconClassName : 'bottom-4 left-4',
          )}
          strokeWidth={1.5}
        />
      )}
      {Icon && (
        <Icon
          className={cn(
            'absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform stroke-white',
            overlayIconClassName,
          )}
          strokeWidth={1}
        />
      )}
      <Image
        width={width}
        height={height}
        ref={imageRef}
        alt={name}
        onLoad={onLoad}
        onError={handleImageError}
        src={src}
        draggable={draggable}
        fill={fill}
        className={imageClassName}
        onDoubleClick={onDoubleClick}
      />
      {error && <div className="absolute top-0 size-full bg-status-failure opacity-50" />}
    </div>
  );

  const renderIcon = (IconComponent: ElementType) => (
    <div className="relative size-full">
      {Icon && (
        <Icon
          className={cn(
            'absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform stroke-white',
            overlayIconClassName,
          )}
          strokeWidth={1}
        />
      )}
      <div ref={iconContainerRef} className={iconContainerClassName}>
        <IconComponent className={iconClassName} strokeWidth={iconStrokeWidth} size={iconSize} />
      </div>
    </div>
  );

  if (thumbnail && !imageError) {
    return renderImage(thumbnail);
  } else {
    switch (fileType?.split('/')[0]) {
      case FileType.Image:
        return renderIcon(FileImage);
      case FileType.Video:
        return renderIcon(FileVideo);
      case FileType.Document:
        return renderIcon(FileText);
      case FileType.Audio:
        return renderIcon(FileAudio);
      case FileType.Other:
      default:
        return renderIcon(File);
    }
  }
};
