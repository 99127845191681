import { Dispatch, SetStateAction, useMemo } from 'react';
import {
  ChevronsDownUp,
  FolderPlusIcon,
  ListTree,
  Search,
  Workflow,
  Tags,
  RectangleEllipsis,
  Users,
  Copy,
  Trash2,
  CopyX,
  LucideIcon,
  Upload,
  FolderSearch2,
  Route,
  TriangleAlert,
} from 'lucide-react';
import { ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuTrigger } from '@/components/ui/context-menu';
import { Tooltip, TooltipContent, TooltipTrigger, TooltipPortal } from '@/components/ui/tooltip';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { PaginationState } from '@/types/pagination';
import { getLocalStorage } from '@/utilities/localStorage';
import { useTreeContext } from '@/context/TreeContext';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { TenantUserRole } from '@/types/tenantUserRole';
import { useUploadStore } from '@/hooks/data/inspector/useUploadStore';
import { SearchOptions } from '@/components/sidebar/search-options';
import { ChevronsRight } from 'lucide-react';
import { UploadState } from '@/types/uploads';
import { SidebarNav } from '@/components/sidebar/sidebar-nav';
import { useAccount } from '@/hooks/data/account/useAccount';
import { EntityType } from '@/types/entity';
import { useDialog } from '@/context/DialogContext';
import { useRouter } from 'next/router';
import { useAuth0 } from '@auth0/auth0-react';
import { useTree } from '@/hooks/data/tree/useTree';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Badge } from '@/components/ui/badge';
import { Tree } from '@/components/sidebar/tree/tree';
import { cn } from '@/lib/utils';

export const styles = 'text-neutral-600 dark:text-neutral-400 duration-150 select-none';
export const hoverStyles = 'hover:bg-neutral-200 hover:text-black dark:hover:bg-neutral-800 dark:hover:text-white';
export const dropStyle = 'bg-neutral-200 text-black dark:bg-neutral-800 dark:text-white';
export const selectedStyles =
  'bg-neutral-200 text-neutral-950 hover:bg-neutral-300 hover:text-black dark:bg-neutral-950 dark:text-white dark:hover:bg-black dark:hover:text-white';

const SidebarButton = (props: {
  label: string;
  icon?: LucideIcon;
  iconProps?: any;
  isActive?: boolean;
  className?: string;
  badge?: React.ReactNode;
  badgeClasses?: string;
}) => {
  const Icon = props?.icon;

  return (
    <Button
      variant="ghost"
      size="sm"
      {...props}
      className={cn(
        'h-9 w-full justify-between pl-5 text-base font-light',
        styles,
        hoverStyles,
        props?.isActive && selectedStyles,
        props?.className,
      )}
    >
      <div className="flex">
        {Icon && props?.iconProps && <Icon {...props.iconProps} />}
        <span className="ml-2 line-clamp-1 break-all text-left">{props?.label}</span>
      </div>
      {props.badge && <Badge className={cn('rounded-full', props.badgeClasses)}>{props.badge}</Badge>}
    </Button>
  );
};

export function Sidebar({
  className,
  handleOnSortChange,
  setPagination,
}: {
  className?: string;
  handleOnSortChange: (value: string) => void;
  setPagination?: Dispatch<SetStateAction<PaginationState>>;
}) {
  const { isTrashPage, isSearchPage, isManagePage, isDuplicatesPage, isUploadPage } = useCurrentPage();
  const { includeSubFolderAssets, sidebarTreeOpenIds, setSidebarTreeOpenIds } = useTreeContext();
  const { query, push } = useRouter();
  const { deselectAllAssets } = useAssetSelection();
  const { openModal } = useDialog();
  const { toggleSubFolders, tree } = useTree();
  const { profile } = useAccount();
  const { isAuthenticated } = useAuth0();
  const { userRole } = profile;
  const { averageProgress, uploadState, erroredAssets } = useUploadStore();

  const assetManagementMenuItems = useMemo(
    () => [
      {
        label: 'Uploads',
        path: '/uploads',
        icon: Upload,
        iconProps: { className: 'size-6 min-w-6 stroke-upload-color', strokeWidth: 2 },
        isActive: isUploadPage,
        badge:
          uploadState !== UploadState.NotStarted ? (
            Object.keys(erroredAssets).length > 0 ? (
              <div className="flex items-center justify-between">
                <div>{Object.keys(erroredAssets).length}</div>
                <TriangleAlert size={14} strokeWidth={2} />
              </div>
            ) : (
              <>{String(averageProgress)}%</>
            )
          ) : undefined,
        onClick: (): void => {
          deselectAllAssets();
          void push({
            pathname: '/uploads',
          });
        },
        badgeClasses:
          Object.keys(erroredAssets).length > 0
            ? '!bg-status-failure'
            : '!bg-inverted-text-color dark:!bg-gray-icon-color',
      },
      {
        label: 'Duplicates',
        path: '/tags',
        icon: CopyX,
        iconProps: { className: 'size-6 min-w-6 stroke-gray-icon-color', strokeWidth: 2 },
        isActive: isDuplicatesPage,
        onClick: (): void => {
          deselectAllAssets();

          void push({
            pathname: '/duplicates',
            query: {
              ...query,
              pageIndex: 1,
              pageSize: getLocalStorage(`${EntityType.Duplicate}/datatable/pagination/pageSize`),
            },
          });
        },
      },
      {
        label: 'Trash',
        path: '/trash',
        icon: Trash2,
        iconProps: { className: 'size-6 min-w-6 stroke-red-500', strokeWidth: 2 },
        isActive: isTrashPage,
        onClick: (): void => {
          deselectAllAssets();

          void push({
            pathname: '/trash',
            query: {
              ...query,
              pageIndex: 1,
              pageSize: getLocalStorage(`${EntityType.Asset}/datatable/pagination/pageSize`),
            },
          });
        },
      },
    ],
    [deselectAllAssets, isDuplicatesPage, isTrashPage, isUploadPage, averageProgress, uploadState, push, query],
  );

  const recentActivityMenuItems = useMemo(
    () => [
      {
        label: 'Last import',
        path: '/last-import',
        icon: FolderSearch2,
        iconProps: { className: 'size-6 min-w-6 stroke-smart-folder-color', strokeWidth: 2 },
        isActive: false,
        disabled: true,
      },
      {
        label: 'Recently Added',
        path: '/recently-added',
        icon: FolderSearch2,
        iconProps: { className: 'size-6 min-w-6 stroke-smart-folder-color', strokeWidth: 2 },
        isActive: false,
        disabled: true,
      },
      {
        label: 'Recently Updated',
        path: '/recently-updated',
        icon: FolderSearch2,
        iconProps: { className: 'size-6 min-w-6 stroke-smart-folder-color', strokeWidth: 2 },
        isActive: false,
        disabled: true,
      },
    ],
    [],
  );

  return isManagePage ? (
    <div className="relative flex h-screen flex-col space-y-px pt-[53px]">
      <SidebarNav
        section={{
          title: 'Configuration',
          icon: ChevronsRight,
        }}
        links={[
          {
            title: 'Workflows',
            icon: Workflow,
            path: '/manage/workflows',
            disabled: false,
          },
          {
            title: 'Tags',
            icon: Tags,
            path: '/manage/tags',
            disabled: false,
          },
          {
            title: 'Metadata',
            icon: RectangleEllipsis,
            path: '/manage/metadata',
            disabled: false,
          },
          {
            title: 'Variants',
            icon: Copy,
            path: '/manage/variants',
            disabled: true,
          },
          {
            title: 'Destinations',
            icon: Route,
            path: '/manage/destinations',
            disabled: false,
          },
        ]}
      />
      {isAuthenticated && userRole === TenantUserRole.Owner && (
        <SidebarNav
          section={{
            title: 'Management',
            icon: ChevronsRight,
          }}
          links={[
            {
              title: 'Users',
              icon: Users,
              path: '/manage/users',
              disabled: false,
            },
          ]}
        />
      )}
    </div>
  ) : (
    <div className={cn('flex h-screen flex-col pt-[53px] @container/sidebar', className)}>
      {isSearchPage ? (
        <SearchOptions className="flex grow" />
      ) : (
        <>
          <div className="relative flex h-12 max-h-12 min-h-12 w-full items-center space-x-2 border-y border-b-void-gap border-t-toolbar-bevel bg-ul-color px-3 py-0 before:pointer-events-none ">
            <div className="relative flex grow">
              <Input
                prependIcon={<Search className="mr-2 size-3.5 shrink-0 opacity-50" />}
                className="z-10 h-9 w-full bg-neutral-100 shadow-sm focus-within:ring-offset-neutral-100 dark:bg-neutral-900 dark:focus-within:ring-offset-neutral-900"
                placeholder="Search directory"
              />
            </div>
            <ToggleGroup type="multiple" variant="default" size="sm">
              <Tooltip>
                <TooltipTrigger asChild>
                  <ToggleGroupItem
                    value="includeSubfolderAssets"
                    aria-label="Include Subfolders"
                    className={cn(!includeSubFolderAssets && 'data-[state=off]:bg-white')}
                    onClick={toggleSubFolders}
                  >
                    <ListTree className="size-6" />
                  </ToggleGroupItem>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>{includeSubFolderAssets ? `Hide` : `Show`} SubFolders assets</TooltipContent>
                </TooltipPortal>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger asChild>
                  <ToggleGroupItem
                    value="collapse"
                    aria-label="Toggle tree collapse"
                    onClick={() => setSidebarTreeOpenIds([])}
                    className="hidden @[12.5rem]/sidebar:flex"
                    disabled={sidebarTreeOpenIds.length === 0}
                  >
                    <ChevronsDownUp className="size-6" />
                  </ToggleGroupItem>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>Collapse</TooltipContent>
                </TooltipPortal>
              </Tooltip>
            </ToggleGroup>
          </div>
          <ContextMenu>
            {/* // TODO: change "h-[calc(100vh-101px)]" to "h-full" once the layout is fixed */}
            <div className="relative flex h-[calc(100vh-101px)] flex-col bg-white dark:bg-[#2C2C2C]">
              <div className="flex flex-col border-b border-neutral-200 px-3 pb-2 pt-1 dark:border-[#444444]">
                {assetManagementMenuItems.map((item) => (
                  <SidebarButton key={item.label} {...item} />
                ))}
              </div>
              <div className="flex flex-col border-b border-neutral-200 px-3 pb-2 pt-1 dark:border-[#444444]">
                {recentActivityMenuItems.map((item) => (
                  <SidebarButton key={item.label} {...item} />
                ))}
              </div>
              <ContextMenuTrigger className="flex grow items-start overflow-x-hidden overflow-y-scroll ">
                <Tree
                  className="flex grow px-3 pb-4 pt-1"
                  handleOnSortChange={handleOnSortChange}
                  setPagination={setPagination}
                  reusable={false}
                  openIds={sidebarTreeOpenIds}
                  tree={tree}
                  setOpenIds={setSidebarTreeOpenIds}
                />
              </ContextMenuTrigger>
            </div>
            <ContextMenuContent>
              <ContextMenuItem onClick={() => openModal('editCreateFolderAlbum', 'createFolder')}>
                <FolderPlusIcon className="mr-2 size-4" />
                New Folder
              </ContextMenuItem>
            </ContextMenuContent>
          </ContextMenu>
        </>
      )}
    </div>
  );
}
