import {
  isValidElement,
  Children,
  cloneElement,
  PropsWithChildren,
  ReactElement,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable';
import { GalleryLayoutProps } from '@/components/layouts/gallery-layout';
import { TrashLayoutProps } from '@/components/layouts/trash-layout';
import { GetTrashResponse } from '@/types/trash';
import { Asset, GetAssets } from '@/types/asset';
import { useUserSettings } from '@/context/UserSettingsContext';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { Navigation } from '@/components/navigation';
import { Inspector } from '@/components/inspector/inspector';
import { Sidebar } from '@/components/sidebar/sidebar';
import { Tenant } from '@/types/tenant';
import { Folder } from '@/types/folder';
import { Album } from '@/types/album';
import { cn } from '@/lib/utils';
import { PaginationState } from '@/types/pagination';

export const DamLayout = ({
  children,
  assets,
  trash,
  showAssetSizeSlider = false,
  showGalleryLayoutOptions = false,
  node,
  tenant,
  handleOnSortChange,
  setPagination,
}: PropsWithChildren<{
  assets?: GetAssets;
  trash?: GetTrashResponse;
  showAssetSizeSlider?: boolean;
  showGalleryLayoutOptions?: boolean;
  node?: Album | Folder | Asset;
  tenant?: Tenant;
  handleOnSortChange?: (value: string) => void;
  setPagination?: Dispatch<SetStateAction<PaginationState>>;
}>) => {
  const [resizeValue, setResizeValue] = useState(0);

  const { showSidebar, showInspector } = useUserSettings();
  const { isPublicRoute } = useCurrentPage();

  const handleResize = (resize: number) => {
    setResizeValue(resize);
  };

  return (
    <div className="flex min-h-screen flex-col">
      {isPublicRoute && (
        <div className="flex h-12 w-full flex-col justify-center border-b border-b-void-gap bg-panel-background px-4 leading-4">
          <div>
            <p className="text-[14px] text-gray-text">{tenant?.name}</p>
            <p className="text-[16px] font-bold uppercase">{node?.name}</p>
          </div>
        </div>
      )}
      <ResizablePanelGroup autoSaveId="mainLayout" direction="horizontal">
        {showSidebar && !isPublicRoute && (
          <>
            <ResizablePanel
              order={1}
              defaultSize={15}
              minSize={10}
              collapsedSize={5}
              className="bg-neutral-300 dark:bg-black"
            >
              {<Sidebar handleOnSortChange={handleOnSortChange!} setPagination={setPagination} />}
            </ResizablePanel>
            <ResizableHandle withHandle />
          </>
        )}
        <ResizablePanel
          order={2}
          minSize={30}
          maxSize={90}
          className={cn(
            'relative flex flex-col bg-container-background',
            isPublicRoute ? 'h-[calc(100dvh-48px)]' : 'max-h-screen',
          )}
          onResize={handleResize}
        >
          <div className={cn('relative @container/main', isPublicRoute ? 'h-[calc(100vh-3rem)]' : 'h-screen')}>
            {!isPublicRoute && (
              <Navigation
                data={assets}
                trash={trash}
                showAssetSizeSlider={showAssetSizeSlider}
                showGalleryLayoutOptions={showGalleryLayoutOptions}
              />
            )}
            {Children.map(children, (child) =>
              isValidElement(child)
                ? cloneElement(child as ReactElement<GalleryLayoutProps | TrashLayoutProps>, {
                    resizeValue,
                  })
                : child,
            )}
          </div>
        </ResizablePanel>
        {(isPublicRoute || showInspector) && (
          <>
            <ResizableHandle withHandle />
            <ResizablePanel order={3} defaultSize={20} minSize={20} className="bg-white dark:bg-black">
              <Inspector data={assets?.assets ?? []} />
            </ResizablePanel>
          </>
        )}
      </ResizablePanelGroup>
    </div>
  );
};
