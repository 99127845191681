import { damRequest } from '@/hooks/damRequest';
import { auditDefaultPageSize } from '@/types/pagination';
import { getUsersResponseSchema } from '@/types/users';

const BASE = '/api/v1/internal/users';

const getUser = async ({ id }: { id: string }, token?: string) => {
  const response = await damRequest('GET', `${BASE}/${id}`, token);

  return response;
  // return getUsersResponseSchema.parse(response);
};

const getUsers = async (
  params: {
    pagination: { limit: number; offset: number };
    sort: { order: string; value: string };
    queryString: string;
  },
  token?: string,
) => {
  const response = await damRequest(
    'GET',
    `${BASE}?offset=${params.pagination?.offset}&limit=${params.pagination?.limit}&filterQuery=${params.queryString}&sortBy=${params.sort?.value}&sortOrder=${params.sort?.order}`,
    token,
  );
  return getUsersResponseSchema.parse(response);
};

const inviteUser = async (body: object, token?: string) => {
  const response = await damRequest('POST', `${BASE}/invitation`, token, { body });

  return response;
};

const deleteUser = ({ id }: { id: string }, token?: string) => damRequest('DELETE', `${BASE}/${id}`, token);

const getUserAuditLog = async (
  params?: { id?: string; pagination?: { offset: number; limit: number } },
  token?: string,
) => {
  const query = new URLSearchParams({
    offset: String(params?.pagination?.offset ?? 0),
    limit: String(params?.pagination?.limit ?? auditDefaultPageSize),
  }).toString();

  const response = await damRequest('GET', `${BASE}/${params?.id}/audit?${query}`, token);
  return response;
};

const updateUser = async ({ id, body }: { id: string; body: object }, token?: string) => {
  const response = await damRequest('PATCH', `${BASE}/${id}`, token, { body });

  return response;
};

export { getUser, updateUser, getUsers, inviteUser, deleteUser, getUserAuditLog };
