import { Fragment } from 'react';
import { cn } from '@/lib/utils';
import { Eye, ImagePlus, Info, MousePointerClick, Trash2Icon } from 'lucide-react';
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialog,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@/components/ui/tooltip';
import { Asset } from '@/types/asset';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from '@/components/ui/dialog';
import { useToast } from '@/components/ui/use-toast';
import { useDialog } from '@/context/DialogContext';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { restoreAssetVersion, deleteAssetVersion } from '@/services/asset.service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { log } from '@/utilities/log';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useRouter } from 'next/router';
import { AssetThumbnail } from '@/components/asset-thumbnail';
import { FileType } from '@/types/fileType';
import { VideoPlayer } from '../video-player';
import { filesize } from 'filesize';

export const VersionsAccordion = ({ asset, disabled }: { asset: Asset; disabled: boolean }) => {
  const { toast } = useToast();
  const { openModal, setOpenDialogId } = useDialog();
  const { query } = useRouter();

  const queryClient = useQueryClient();

  const deleteAssetVersionWithAuth = useAuthenticatedQueryFn(deleteAssetVersion);
  const restoreAssetVersionWithAuth = useAuthenticatedQueryFn(restoreAssetVersion);

  const deleteAssetVersionMutation = useMutation({
    mutationFn: deleteAssetVersionWithAuth,
    onSuccess: () => {
      toast({
        title: 'Removed asset version',
        description: `Asset version has been removed successfully.`,
      });

      void queryClient.invalidateQueries({ queryKey: ['asset'] });
    },
    onError: (err) => {
      log(err);
    },
  });

  const restoreAssetVersionMutation = useMutation({
    mutationFn: restoreAssetVersionWithAuth,
    onSuccess: () => {
      toast({
        title: 'Asset version restored',
        description: `Asset version has been successfully restored.`,
      });

      void queryClient.invalidateQueries({ queryKey: ['asset'] });

      void queryClient.invalidateQueries({
        queryKey: [
          'assetData',
          {
            folderId: query.folder,
            limit: Number(query.pageSize),
            offset: (Number(query.page) - 1) * 10,
          },
        ],
      });
    },
    onError: (err) => {
      log(err);
    },
  });

  return (
    <AccordionItem value="versions" disabled={disabled}>
      <AccordionTrigger disabled={disabled}>Versions</AccordionTrigger>
      {!disabled && (
        <AccordionContent className="flex flex-col gap-3">
          <ScrollArea className="h-72 w-full rounded-md border border-neutral-200 bg-white dark:border-neutral-800 dark:bg-neutral-950">
            <div className="py-2 @[18rem]/inspector:px-4">
              {asset?.versions &&
                [...asset.versions]
                  .reverse()
                  .map(({ id, active, fileSize, width, height, fileType, originalFileName, thumbnail }) => (
                    <Fragment key={id}>
                      <div className="flex items-center justify-between gap-2">
                        <div className="flex items-center gap-3 @[18rem]/inspector:gap-3">
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <div
                                className={cn(
                                  'ml-3 size-2 min-h-2 min-w-2 rounded-full @[18rem]/inspector:ml-0',
                                  active
                                    ? 'bg-green-300 shadow-[0_0_5px_1px_rgb(134,239,172,1)]'
                                    : 'bg-neutral-300 dark:bg-neutral-700',
                                )}
                              />
                            </TooltipTrigger>
                            <TooltipPortal>
                              <TooltipContent>{active ? 'Current version' : 'Inactive version'}</TooltipContent>
                            </TooltipPortal>
                          </Tooltip>

                          <div>
                            <div key={id} className="line-clamp-2 break-all text-sm">
                              {originalFileName}
                            </div>
                          </div>
                        </div>

                        <div className="mr-3 flex gap-0.5 @[18rem]/inspector:mr-0">
                          <Popover>
                            <PopoverTrigger asChild>
                              <Button className="group size-6 min-h-4 p-0" variant="ghost">
                                <Info className="size-4 opacity-30 group-hover:opacity-100" />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent>
                              <div className="flex flex-col space-y-2">
                                <div className="flex items-center justify-start gap-2">
                                  <p
                                    className={cn(
                                      'inline rounded-sm px-1.5 py-0.5 text-xs uppercase leading-none',
                                      fileType === 'image'
                                        ? 'bg-violet-100 text-violet-400 dark:bg-violet-800'
                                        : 'bg-neutral-100 text-neutral-400 dark:bg-neutral-800',
                                    )}
                                  >
                                    {fileType}
                                  </p>
                                </div>

                                <p className=" max-w-64 text-sm font-medium">{originalFileName}</p>
                                <Separator />
                                <div className="flex flex-col gap-2">
                                  {fileSize && (
                                    <div className="flex items-center gap-2">
                                      <p className="w-1/4 text-right text-xs leading-none text-neutral-500">
                                        File size:
                                      </p>
                                      <div className="text-xs font-bold leading-none text-white">
                                        {filesize(fileSize, { standard: 'jedec' })}
                                      </div>
                                    </div>
                                  )}
                                  {Boolean(width) && (
                                    <div className="flex items-center gap-2">
                                      <p className="w-1/4 text-right text-xs leading-none text-neutral-500">Width:</p>
                                      <div className="text-xs font-bold leading-none text-white">{width} px</div>
                                    </div>
                                  )}
                                  {Boolean(height) && (
                                    <div className="flex items-center gap-2">
                                      <p className="w-1/4 text-right text-xs leading-none text-neutral-500">Height:</p>
                                      <div className="text-xs font-bold leading-none text-white">{height} px</div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </PopoverContent>
                          </Popover>

                          {active ? (
                            <>
                              {fileType === FileType.Image || fileType === FileType.Video ? (
                                <Dialog>
                                  <DialogTrigger asChild>
                                    <Button
                                      className="group size-6 min-h-4 p-0 hover:bg-neutral-100 data-[state=open]:bg-neutral-100 dark:hover:bg-neutral-800 dark:data-[state=open]:bg-neutral-800 "
                                      variant="ghost"
                                    >
                                      <Eye className="size-4 opacity-30 group-hover:opacity-100" />
                                    </Button>
                                  </DialogTrigger>
                                  <DialogContent className="sm:max-w-[425px]">
                                    <DialogHeader>
                                      <DialogTitle>{originalFileName}</DialogTitle>
                                    </DialogHeader>
                                    {fileType === FileType.Video ? (
                                      <VideoPlayer variants={asset.variants} />
                                    ) : (
                                      <AssetThumbnail
                                        name={originalFileName}
                                        fileType={fileType}
                                        thumbnail={thumbnail}
                                        iconSize={200}
                                        iconStrokeWidth={0.5}
                                        imageClassName="object-contain"
                                        iconClassName="stroke-neutral-500 dark:stroke-neutral-400"
                                        imageContainerClassName="relative aspect-square w-full"
                                        iconContainerClassName="flex justify-center rounded-md border border-neutral-200 bg-white shadow-sm dark:border-neutral-800 dark:bg-neutral-900"
                                      />
                                    )}
                                    <DialogFooter>
                                      <DialogClose asChild>
                                        <Button type="button" variant="secondary">
                                          Close
                                        </Button>
                                      </DialogClose>
                                    </DialogFooter>
                                  </DialogContent>
                                </Dialog>
                              ) : (
                                <>
                                  {/* Commented out till functionality is implemented */}
                                  {/* <Button
                                    className="group size-6 min-h-4 p-0 hover:bg-neutral-100 data-[state=open]:bg-neutral-100 dark:hover:bg-neutral-800 dark:data-[state=open]:bg-neutral-800 "
                                    variant="ghost"
                                  >
                                    <Download className="size-4 opacity-30 group-hover:opacity-100" />
                                  </Button> */}
                                </>
                              )}
                            </>
                          ) : (
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <Button
                                  className="group size-6 min-h-4 p-0 hover:bg-neutral-100 data-[state=open]:bg-neutral-100 dark:hover:bg-neutral-800 dark:data-[state=open]:bg-neutral-800 "
                                  variant="ghost"
                                >
                                  <EllipsisVerticalIcon className="size-4 opacity-50 group-hover:opacity-100" />
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent align="end">
                                <DropdownMenuItem
                                  onClick={() =>
                                    restoreAssetVersionMutation.mutate({ asset_id: asset.id, version_id: id })
                                  }
                                >
                                  <MousePointerClick className="mr-2 size-4 text-green-500" />
                                  Activate
                                </DropdownMenuItem>
                                {fileType === FileType.Image || fileType === FileType.Video ? (
                                  <Dialog>
                                    <DialogTrigger asChild>
                                      <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                                        <Eye className="mr-2 size-4" />
                                        View
                                      </DropdownMenuItem>
                                    </DialogTrigger>

                                    <DialogContent className="sm:max-w-[425px]">
                                      <DialogHeader>
                                        <DialogTitle>{originalFileName}</DialogTitle>
                                      </DialogHeader>
                                      {fileType === FileType.Image ? (
                                        <AssetThumbnail
                                          name={originalFileName}
                                          fileType={fileType}
                                          thumbnail={thumbnail}
                                          iconSize={200}
                                          iconStrokeWidth={0.5}
                                          imageClassName="object-contain"
                                          iconClassName="stroke-neutral-500 dark:stroke-neutral-400"
                                          imageContainerClassName="relative aspect-square w-full"
                                          iconContainerClassName="flex justify-center rounded-md border border-neutral-200 bg-white shadow-sm dark:border-neutral-800 dark:bg-neutral-900"
                                        />
                                      ) : (
                                        <VideoPlayer variants={asset.variants} />
                                      )}

                                      <DialogFooter>
                                        <DialogClose asChild>
                                          <Button type="button" variant="secondary">
                                            Close
                                          </Button>
                                        </DialogClose>
                                      </DialogFooter>
                                    </DialogContent>
                                  </Dialog>
                                ) : (
                                  <>
                                    {/* Commented out till functionality is implemented */}
                                    {/* <Button
                                    className="group size-6 min-h-4 p-0 hover:bg-neutral-100 data-[state=open]:bg-neutral-100 dark:hover:bg-neutral-800 dark:data-[state=open]:bg-neutral-800 "
                                    variant="ghost"
                                  >
                                    <Download className="size-4 opacity-30 group-hover:opacity-100" />
                                  </Button> */}
                                  </>
                                )}

                                <DropdownMenuSeparator />
                                <AlertDialog onOpenChange={() => setOpenDialogId(null)}>
                                  <AlertDialogTrigger asChild>
                                    <DropdownMenuItem
                                      onSelect={(e) => e.preventDefault()}
                                      className="text-red-600 focus:!bg-red-100 focus:!text-red-600 dark:focus:!bg-red-950 dark:focus:!text-red-500"
                                    >
                                      <Trash2Icon className="mr-2 size-4" />
                                      Trash
                                    </DropdownMenuItem>
                                  </AlertDialogTrigger>

                                  <AlertDialogContent>
                                    <AlertDialogHeader>
                                      <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                                      <AlertDialogDescription>
                                        This action cannot be undone. This will permanently delete asset version
                                      </AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <AlertDialogFooter>
                                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                                      <AlertDialogAction
                                        className="!hover:bg-red-600 !bg-red-900 !text-red-300"
                                        onClick={() =>
                                          deleteAssetVersionMutation.mutate({
                                            asset_id: asset.id,
                                            version_id: id,
                                          })
                                        }
                                      >
                                        Delete
                                      </AlertDialogAction>
                                    </AlertDialogFooter>
                                  </AlertDialogContent>
                                </AlertDialog>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          )}
                        </div>
                      </div>
                      <Separator className="my-2" />
                    </Fragment>
                  ))}
            </div>
          </ScrollArea>
          <div className="flex justify-center">
            <Button
              onClick={() => openModal('uploadAssetVersion', undefined, { id: asset.id })}
              className="flex w-full gap-3 text-left @[18rem]/inspector:!w-auto @[18rem]/inspector:gap-2 @[18rem]/inspector:text-center"
              variant="secondary"
            >
              <ImagePlus className="size-4 min-w-4" />
              Upload new version
            </Button>
          </div>
        </AccordionContent>
      )}
    </AccordionItem>
  );
};
