import { useEffect, useMemo, useState } from 'react';
import { Pagination } from '@/types';
import { getLocalStorage } from '@/utilities/localStorage';
import { defaultPaginationIndex, defaultPaginationSize, pageSizeOptions, PaginationState } from '@/types/pagination';
import { useRouter } from 'next/router';
import { EntityType } from '@/types/entity';

export const DOTS = '...';

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({ totalCount, pageSize, siblingCount = 1, currentPage }: Pagination) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // Pages count = siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    // If the number of pages is less than the page numbers we want to show in our paginationComponent, we return the range [1..totalPageCount]
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    // Exclude dots when only one position is left after/before the left/right page count to maintain Pagination component size
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};

export const useDataTablePagination = (entity: EntityType) => {
  const router = useRouter();
  const { query, pathname, replace } = router;
  const [ready, setReady] = useState<boolean>(false);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: query.pageIndex ? Number(query.pageIndex) - 1 : defaultPaginationIndex,
    pageSize:
      query.pageSize && pageSizeOptions.includes(Number(query.pageSize))
        ? Number(query.pageSize)
        : defaultPaginationSize,
  });
  const offset = useMemo(() => pagination.pageSize * pagination.pageIndex, [pagination.pageSize, pagination.pageIndex]);
  const limit = useMemo(() => pagination.pageSize, [pagination.pageSize]);

  //TODO : On load, if there are no query params, set them - TODO : Not working ONLY on page refresh
  useEffect(() => {
    if (!query.pageIndex && !query.pageSize) {
      const localStoragePageSize = getLocalStorage(`${entity}/datatable/pagination/pageSize`);
      setPagination({
        pageIndex: pagination.pageIndex,
        pageSize: localStoragePageSize ? Number(localStoragePageSize) : pagination.pageSize,
      });
    }
    setReady(true);
  }, []);

  const setQueryParams = (
    pageIndex: number,
    pageSize: number,
    sortValue: string,
    sortOrder: string,
    queryString: string,
  ) => {
    const newQuery = {
      ...query,
      pageIndex: String(pageIndex),
      pageSize: String(pageSize),
      sortValue,
      sortOrder,
      queryString,
    };

    void replace(
      {
        pathname: pathname === '/' ? '/browse' : pathname,
        query: newQuery,
      },
      undefined,
      { shallow: true },
    );
  };

  return { pagination, setPagination, offset, limit, ready, setQueryParams };
};
