import { createContext, useContext, useState, useEffect, FC, PropsWithChildren, Dispatch, SetStateAction } from 'react';
import { getLocalStorage, setLocalStorage, LOCAL_STORAGE_KEYS } from '@/utilities/localStorage';
import { GalleryView } from '@/types';
import { VisibilityState } from '@tanstack/react-table';
import { pageSizeOptions, PageSizes } from '@/types/pagination';

// These classes need to be safelisted in tailwind.config.js
// https://tailwindcss.com/docs/content-configuration#safelisting-classes
export const gridColsLookup: { [key: number]: string } = {
  100: 'grid-cols-2',
  80: 'grid-cols-3',
  60: 'grid-cols-4',
  40: 'grid-cols-5',
  20: 'grid-cols-6',
};

interface SettingsContextType {
  showSidebar: boolean;
  setShowSidebar: Dispatch<SetStateAction<boolean>>;
  showInspector: boolean;
  setShowInspector: Dispatch<SetStateAction<boolean>>;
  assetSize: Array<number>;
  setAssetSize: Dispatch<SetStateAction<Array<number>>>;
  galleryView: GalleryView;
  setGalleryView: Dispatch<SetStateAction<GalleryView>>;
  pageSizes: PageSizes;
  assetsPerPage: number;
  setAssetsPerPage: Dispatch<SetStateAction<number>>;
  visibleColumns: VisibilityState;
  setVisibleColumns: Dispatch<SetStateAction<VisibilityState>>;
}

const UserSettingsContext = createContext<SettingsContextType | undefined>(undefined);

export const UserSettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const pageSizes: PageSizes = pageSizeOptions;

  const [showSidebar, setShowSidebar] = useState<boolean>(() => {
    const storedValue = getLocalStorage(LOCAL_STORAGE_KEYS.showSidebar);
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });

  const [showInspector, setShowInspector] = useState<boolean>(() => {
    const storedValue = getLocalStorage(LOCAL_STORAGE_KEYS.showInspector);
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });

  const [assetSize, setAssetSize] = useState<Array<number>>(() => {
    const storedValue = getLocalStorage(LOCAL_STORAGE_KEYS.assetSize);
    return storedValue !== null ? JSON.parse(storedValue) : [20];
  });

  const [galleryView, setGalleryView] = useState<GalleryView>(() => {
    const storedValue = getLocalStorage(LOCAL_STORAGE_KEYS.galleryView);
    return storedValue !== null ? JSON.parse(storedValue) : GalleryView.TILE;
  });

  const [assetsPerPage, setAssetsPerPage] = useState<number>(() => {
    const storedValue = getLocalStorage(LOCAL_STORAGE_KEYS.assetsPerPage);
    return storedValue !== null ? JSON.parse(storedValue) : pageSizeOptions[0];
  });

  const [visibleColumns, setVisibleColumns] = useState<VisibilityState>(() => {
    const storedValue = getLocalStorage(LOCAL_STORAGE_KEYS.visibleColumns);
    return storedValue !== null
      ? JSON.parse(storedValue)
      : { thumbnail: true, name: true, type: true, nodeId: false, slug: false, description: false }; // default visible columns
  });

  useEffect(() => {
    setLocalStorage(LOCAL_STORAGE_KEYS.visibleColumns, JSON.stringify(visibleColumns));
  }, [visibleColumns]);

  useEffect(() => {
    setLocalStorage(LOCAL_STORAGE_KEYS.showSidebar, JSON.stringify(showSidebar));
  }, [showSidebar]);

  useEffect(() => {
    setLocalStorage(LOCAL_STORAGE_KEYS.showInspector, JSON.stringify(showInspector));
  }, [showInspector]);

  useEffect(() => {
    setLocalStorage(LOCAL_STORAGE_KEYS.assetSize, JSON.stringify(assetSize));
  }, [assetSize]);

  useEffect(() => {
    setLocalStorage(LOCAL_STORAGE_KEYS.galleryView, JSON.stringify(galleryView));
  }, [galleryView]);

  useEffect(() => {
    setLocalStorage(LOCAL_STORAGE_KEYS.assetsPerPage, JSON.stringify(assetsPerPage));
  }, [assetsPerPage]);

  return (
    <UserSettingsContext.Provider
      value={{
        showSidebar,
        setShowSidebar,
        showInspector,
        setShowInspector,
        assetSize,
        setAssetSize,
        galleryView,
        setGalleryView,
        pageSizes,
        assetsPerPage,
        setAssetsPerPage,
        visibleColumns,
        setVisibleColumns,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

export const useUserSettings = (): SettingsContextType => {
  const context = useContext(UserSettingsContext);
  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};
