import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { useFormErrorHandler, BackendError } from '@/hooks/useFormErrorHandler';
import { BookImage, Loader2, LucideFolder } from 'lucide-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FileInformationAccordion } from '@/components/inspector/file-information-accordion';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { useDataTableSelection } from '@/context/DataTableSelectionContext';
import { DestinationAccordion } from '@/components/inspector/destination-accordion';
import { DestinationFields } from '@/components/inspector/entity-fields/destination-fields';
import { VersionsAccordion } from '@/components/inspector/versions-accordion';
import { WorkflowAccordion } from '@/components/inspector/workflow-accordion';
import { VariantsAccordion } from '@/components/inspector/variants-accordion';
import { AuditLogAccordion } from '@/components/inspector/audit-log-item';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { useForm, useWatch } from 'react-hook-form';
import { UploadsAccordion } from '@/components/inspector/uploads-accordion';
import { InvitationFields } from '@/components/inspector/entity-fields/invitation-fields';
import { SharesAccordion } from '@/components/inspector/shares-accordion';
import { MetadataEditor } from '@/components/inspector/metadata-accordion/metadata-editor';
import { MetadataFields } from '@/components/inspector/entity-fields/metadata-fields';
import { WorkflowFields } from '@/components/inspector/entity-fields/workflow-fields';
import { usePublicAsset } from '@/hooks/shares/useShares';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { InspectorImage } from '@/components/inspector/inspector-image';
import { VariantFields } from '@/components/inspector/entity-fields/variant-fields';
import { TagsAccordion } from '@/components/inspector/tags-accordion';
import { renameFolder } from '@/services/folder.service';
import { updateAsset } from '@/services/asset.service';
import { renameAlbum } from '@/services/album.service';
import { zodResolver } from '@hookform/resolvers/zod';
import { UserFields } from '@/components/inspector/entity-fields/user-fields';
import { EntityType } from '@/types/entity';
import { TagFields } from '@/components/inspector/entity-fields/tag-fields';
import { useFolder } from '@/hooks/data/folder/useFolder';
import { useRouter } from 'next/router';
import { useAlbum } from '@/hooks/data/albums/useAlbum';
import { useAsset } from '@/hooks/data/assets/useAsset';
import { useToast } from '@/components/ui/use-toast';
import { Textarea } from '@/components/ui/textarea';
import { Variants } from '@/types/asset';
import { NodeType } from '@/types/tree';
import { useTree } from '@/hooks/data/tree/useTree';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Asset } from '@/types/asset';
import { cn } from '@/lib/utils';
import { z } from 'zod';
import { UploadState } from '@/types/uploads';
import { useUploadStore } from '@/hooks/data/inspector/useUploadStore';
import Image from 'next/image';
import { Progress } from '@/components/ui/progress';
import { filesize } from 'filesize';
import { Badge } from '@/components/ui/badge';

export const Inspector: FC<{ data: Array<Asset> }> = ({ data }) => {
  const [currentAccordionItems, setCurrentAccordionItems] = useState<Array<string>>([]);
  const [scrollY, setScrollY] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  const { currentSelectedType: typeFromTree, selectedAlbum, selectedFolder } = useTree();
  const { selectedEntity, selectedEntityType } = useDataTableSelection();
  const { selectedAssetIds: browserSelectedAssetsIds } = useAssetSelection();
  const {
    uploadState,
    selectedAssetIds: uploadSelectedAssetsIds,
    assetsUploadProgress,
    filesData,
    erroredAssets,
  } = useUploadStore();

  const { pathname, query } = useRouter();
  const { toast } = useToast();
  const { isManagePage, isPublicRoute, isUploadPage, isDuplicatesPage } = useCurrentPage();

  const selectedAssetIds = useMemo(
    () => (isUploadPage ? uploadSelectedAssetsIds : browserSelectedAssetsIds),
    [browserSelectedAssetsIds, uploadSelectedAssetsIds, isUploadPage],
  );

  const currentSelectedType = isPublicRoute
    ? selectedAssetIds.length > 0
      ? NodeType.Assets
      : undefined
    : typeFromTree;

  const queryClient = useQueryClient();
  const isSingleAssetPage = pathname === '/asset/[id]';
  const multipleAssetSelected = selectedAssetIds.length > 1;
  const selectedAssets = data?.filter((asset) => selectedAssetIds.some((id) => id.id === asset.id));
  const stackedAssets = selectedAssets.slice(0, 5);
  const scrollRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const temporaryAssets = useMemo(() => {
    const selectedIds = selectedAssetIds.map((asset) => asset.id);
    return filesData.filter((file) => selectedIds.includes(file.id));
  }, [filesData, selectedAssetIds]);

  useEffect(() => {
    if (selectedAssetIds.length === 0) {
      setCurrentAccordionItems([]);
    } else {
      setCurrentAccordionItems(currentAccordionItems);
    }
  }, [selectedAssetIds, selectedFolder, selectedAlbum]);

  /*   const SelectedAssetContainerMaxHeight: number = 176;
  const SelectedAssetContainerMinHeight: number = 50; */

  const formSchema = z.object({
    name: z.string().min(2, {
      message: 'Asset name must be at least 2 characters.',
    }),
    slug: z.string().optional(),
    description: z.any(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      slug: '',
      description: '',
    },
  });

  const watchedName = useWatch({ control: form.control, name: 'name' });
  const watchedDescription = useWatch({ control: form.control, name: 'description' });

  const updateAssetWithAuth = useAuthenticatedQueryFn(updateAsset);
  const renameAlbumWithAuth = useAuthenticatedQueryFn(renameAlbum);
  const renameFolderWithAuth = useAuthenticatedQueryFn(renameFolder);

  const { handleError } = useFormErrorHandler(form.setError, form.getValues);

  const {
    asset: privateAsset,
    isAssetLoading,
    isAssetFetching,
  } = useAsset(selectedAssetIds[0]?.id, form, {
    refetchOnWindowFocus: false,
    enabled:
      selectedAssetIds.length === 1 &&
      currentSelectedType === NodeType.Assets &&
      !isPublicRoute &&
      (isUploadPage ? assetsUploadProgress[selectedAssetIds[0]?.id]?.uploaded === true : true),
  });

  const { publicAsset } = usePublicAsset(
    {
      shareId: query.shareId as string,
      assetId: selectedAssetIds[0]?.id,
    },
    form,
    {
      enabled:
        selectedAssetIds.length === 1 &&
        currentSelectedType === NodeType.Assets &&
        isPublicRoute &&
        Boolean(query.shareId),
    },
  );

  const asset = useMemo(() => {
    return isPublicRoute ? publicAsset : privateAsset;
  }, [isPublicRoute, publicAsset, privateAsset]);

  const { album, isAlbumLoading, isAlbumFetching } = useAlbum(selectedAlbum, form, {
    refetchOnWindowFocus: false,
    enabled: currentSelectedType === NodeType.Albums && selectedAlbum !== undefined,
  });

  const { folder, isFolderLoading, isFolderFetching } = useFolder(selectedFolder, form, {
    refetchOnWindowFocus: false,
    enabled: currentSelectedType === NodeType.Folders && selectedFolder !== undefined,
  });

  const isLoading = isAssetLoading || isAlbumLoading || isFolderLoading;
  const isFetching = isAssetFetching || isAlbumFetching || isFolderFetching;

  // Reset "information" form if accordion is opened and asset data is fetching or loading
  useEffect(() => {
    if (isLoading || isFetching) {
      form.reset({
        name: '',
        slug: '',
        description: '',
      });
    }
  }, [form, isLoading, isFetching]);

  const updateAssetMutation = useMutation({
    mutationFn: updateAssetWithAuth,
    onSuccess: (result) => {
      const onlyDescriptionChanged = watchedName === asset?.name && watchedDescription !== asset?.description;

      toast({
        title: onlyDescriptionChanged ? 'Description Updated' : 'Rename Successful',
        description: (
          <>
            {onlyDescriptionChanged ? (
              `The description for "${result.name}" has been successfully updated.`
            ) : (
              <>
                {currentSelectedType?.slice(0, -1)} has been successfully renamed to &quot;
                <span className="font-bold">{result.name}</span>
                &quot;.
              </>
            )}
          </>
        ),
      });

      setIsEdit((prev) => prev === true && false);

      void queryClient.invalidateQueries({ queryKey: [`${isDuplicatesPage ? 'duplicates' : 'assetData'}`] });
    },
    onError: (err: BackendError) => {
      handleError(err);
      setIsEdit(true);
    },
  });

  const renameAlbumMutation = useMutation({
    mutationFn: renameAlbumWithAuth,
    onSuccess: (result) => {
      const onlyDescriptionChanged = watchedName === album?.name && watchedDescription !== album?.description;

      toast({
        title: onlyDescriptionChanged ? 'Description Updated' : 'Rename Successful',
        description: (
          <>
            {onlyDescriptionChanged ? (
              `The description for "${result.name}" has been successfully updated.`
            ) : (
              <>
                {currentSelectedType?.slice(0, -1)} has been successfully renamed to &quot;
                <span className="font-bold">{result.name}</span>
                &quot;.
              </>
            )}
          </>
        ),
      });

      void queryClient.invalidateQueries({ queryKey: ['tree'] });

      setIsEdit((prev) => prev === true && false);
    },
    onError: (err: BackendError) => {
      handleError(err);
      setIsEdit(true);
    },
  });

  const renameFolderMutation = useMutation({
    mutationFn: renameFolderWithAuth,
    onSuccess: (result) => {
      const onlyDescriptionChanged = watchedName === folder?.name && watchedDescription !== folder?.description;

      toast({
        title: onlyDescriptionChanged ? 'Description Updated' : 'Rename Successful',
        description: (
          <>
            {onlyDescriptionChanged ? (
              `The description for "${result.name}" has been successfully updated.`
            ) : (
              <>
                {currentSelectedType?.slice(0, -1)} has been successfully renamed to &quot;
                <span className="font-bold">{result.name}</span>
                &quot;.
              </>
            )}
          </>
        ),
      });

      void queryClient.invalidateQueries({ queryKey: ['tree'] });

      setIsEdit((prev) => prev === true && false);
    },
    onError: (err: BackendError) => {
      handleError(err);
      setIsEdit(true);
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    if (currentSelectedType === NodeType.Folders) {
      renameFolderMutation.mutate({
        id: selectedFolder,
        name: values.name,
        description: values.description,
      });
    } else if (currentSelectedType === NodeType.Albums) {
      renameAlbumMutation.mutate({
        id: selectedAlbum,
        name: values.name,
        description: values.description,
        slug: values.slug,
      });
    } else {
      updateAssetMutation.mutate({
        id: selectedAssetIds[0].id,
        body: { name: values.name, description: values.description },
      });
    }
  }

  const handleScroll = () => {
    const position = scrollRef.current?.scrollTop ?? 0;
    setScrollY(position);
  };

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [selectedAssetIds]);

  /*   const selectedAssetContainerHeight = Math.min(
    Math.max(SelectedAssetContainerMaxHeight - scrollY, SelectedAssetContainerMinHeight),
    SelectedAssetContainerMaxHeight,
  ); */

  const renderManageInspector = useCallback(() => {
    switch (selectedEntityType) {
      case EntityType.User:
        return <UserFields user={selectedEntity!} />;
      case EntityType.Invitation:
        return <InvitationFields invitation={selectedEntity!} />;
      case EntityType.Tag:
        return <TagFields tag={selectedEntity!} />;
      case EntityType.Metadata:
        return selectedEntity?.original.id && <MetadataFields metadataId={selectedEntity?.original.id} />;
      case EntityType.Workflow:
        return selectedEntity?.original.id && <WorkflowFields workflowId={selectedEntity?.original.id} />;
      case EntityType.Destination:
        return selectedEntity?.original.id && <DestinationFields destinationId={selectedEntity?.original.id} />;
      case EntityType.Variant:
        return <VariantFields variant={selectedEntity!} />;
      default:
        return <div>Unknown entity type</div>;
    }
  }, [selectedEntity, selectedEntityType]);

  const renderContent = () => {
    if (isManagePage) {
      return (
        <>
          {selectedEntity && selectedEntityType ? (
            <div className="relative h-screen overflow-y-scroll pt-[53px]" ref={scrollRef}>
              {renderManageInspector()}
            </div>
          ) : (
            <h2 className="flex h-full items-center justify-center text-balance p-4 text-center text-lg font-semibold tracking-tight text-neutral-400">
              No row selected
            </h2>
          )}
        </>
      );
    }
    if (isUploadPage && uploadState === UploadState.NotStarted) {
      return <UploadsAccordion />;
    }

    return (
      <>
        {selectedAssetIds.length > 0 || selectedFolder || selectedAlbum ? (
          <>
            <div
              className={cn(
                'sticky z-50 flex max-h-12 min-h-12 items-center justify-center gap-3 border-b border-t border-toolbar-bevel border-b-void-gap bg-ul-color px-4 text-lg font-semibold tracking-tight',
                !isPublicRoute && 'mt-[53px]',
              )}
            >
              <p
                className={cn(
                  'text-balance text-center text-sm text-black transition-all duration-200 dark:text-white',
                  scrollY >= 96 ? 'mr-0' : '-mr-10',
                )}
              >
                Selected{' '}
                {currentSelectedType === NodeType.Assets
                  ? multipleAssetSelected && !isSingleAssetPage
                    ? 'assets'
                    : 'asset'
                  : currentSelectedType?.slice(0, -1)}
              </p>

              <div
                className={cn(
                  'relative flex size-8 items-center transition-all duration-200',
                  scrollY >= 96 ? 'scale-100' : 'scale-0',
                )}
              >
                {currentSelectedType === NodeType.Assets ? (
                  selectedAssetIds.length > 1 ? (
                    isUploadPage ? (
                      <>
                        {temporaryAssets.map((temporaryAsset, i) => (
                          <div
                            className={cn(
                              'absolute flex size-full origin-center overflow-hidden rounded-md shadow-lg transition-all duration-200 first:relative',
                            )}
                            key={temporaryAsset.id}
                            style={{
                              transform: `rotate(${(30 / temporaryAssets.length) * i}deg)`,
                              zIndex: temporaryAssets.length - i,
                            }}
                          >
                            <Image
                              alt={temporaryAsset.id}
                              src={temporaryAsset.preview}
                              width={200}
                              height={200}
                              unoptimized={false}
                              className="relative object-cover"
                            />
                          </div>
                        ))}
                      </>
                    ) : (
                      stackedAssets.map((props: Asset, index: number) => (
                        <InspectorImage
                          classname="absolute"
                          key={`${props.id}-header`}
                          {...props}
                          index={index}
                          stackedAssetsLength={stackedAssets.length}
                        />
                      ))
                    )
                  ) : asset ? (
                    <InspectorImage
                      classname="absolute"
                      key={`${asset.id}-header`}
                      {...asset}
                      index={0}
                      stackedAssetsLength={stackedAssets.length}
                    />
                  ) : (
                    isUploadPage &&
                    temporaryAssets[0] && (
                      <Image
                        key={temporaryAssets[0].id}
                        alt={temporaryAssets[0].id}
                        src={temporaryAssets[0].preview}
                        width={200}
                        height={200}
                        unoptimized={false}
                        className="relative object-cover"
                      />
                    )
                  )
                ) : (
                  <div
                    className={cn(
                      'flex aspect-square size-8 items-center justify-center rounded-md duration-200',
                      currentSelectedType === NodeType.Folders && 'bg-violet-100 dark:bg-violet-950',
                      currentSelectedType === NodeType.Albums && 'bg-neutral-100 dark:bg-neutral-950',
                    )}
                  >
                    {currentSelectedType === NodeType.Folders ? (
                      <LucideFolder className="size-5 stroke-violet-500 dark:stroke-violet-700" strokeWidth={2} />
                    ) : (
                      <BookImage className="size-5 stroke-neutral-600 dark:stroke-neutral-700" strokeWidth={2} />
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="overflow-y-scroll px-3 @[18rem]/inspector:px-6" ref={scrollRef}>
              <div
                className={cn('relative mx-auto mb-12 mt-8 flex aspect-square w-full justify-center md:my-12')}
                ref={containerRef}
                style={{
                  width: `${asset?.variants[Variants.ThumbnailInternal]?.width}px`,
                  height: `${asset?.variants[Variants.ThumbnailInternal]?.height}px`,
                }}
              >
                {currentSelectedType === NodeType.Assets ? (
                  selectedAssetIds.length > 1 ? (
                    isUploadPage ? (
                      <>
                        {temporaryAssets.map((temporaryAsset, i) => (
                          <div
                            className={cn(
                              'absolute flex size-full origin-center overflow-hidden rounded-md shadow-lg transition-all duration-200 first:relative',
                            )}
                            key={temporaryAsset.id}
                            style={{
                              transform: `rotate(${(30 / temporaryAssets.length) * i}deg)`,
                              zIndex: temporaryAssets.length - i,
                            }}
                          >
                            <Image
                              alt={temporaryAsset.id}
                              src={temporaryAsset.preview}
                              width={200}
                              height={200}
                              unoptimized={false}
                              className="object-cover"
                            />
                          </div>
                        ))}
                      </>
                    ) : (
                      stackedAssets.map((props: Asset, index: number) => (
                        <InspectorImage
                          classname="absolute first:relative"
                          key={`${props.id}-container`}
                          {...props}
                          index={index}
                          stackedAssetsLength={stackedAssets.length}
                        />
                      ))
                    )
                  ) : asset ? (
                    <InspectorImage
                      classname="absolute"
                      key={`${asset.id}-header`}
                      {...asset}
                      index={0}
                      stackedAssetsLength={stackedAssets.length}
                    />
                  ) : (
                    isUploadPage &&
                    temporaryAssets[0] && (
                      <Image
                        key={temporaryAssets[0].id}
                        alt={temporaryAssets[0].id}
                        src={temporaryAssets[0].preview}
                        width={200}
                        height={200}
                        unoptimized={false}
                        className="object-cover"
                      />
                    )
                  )
                ) : (
                  <div
                    className={cn(
                      'flex aspect-square items-center justify-center rounded-md duration-200',
                      currentSelectedType === NodeType.Folders && 'bg-violet-100 dark:bg-violet-950',
                      currentSelectedType === NodeType.Albums && 'bg-teal-100 dark:bg-teal-950',
                    )}
                  >
                    {currentSelectedType === NodeType.Folders ? (
                      <LucideFolder className="size-14 stroke-violet-500 dark:stroke-violet-700" strokeWidth={1} />
                    ) : (
                      <BookImage className="size-14 stroke-teal-600 dark:stroke-teal-700" strokeWidth={1} />
                    )}
                  </div>
                )}
              </div>

              {isUploadPage && !asset && temporaryAssets.length > 0 ? (
                <>
                  {temporaryAssets.map((temporaryAsset) => (
                    <div key={temporaryAsset.name} className="flex flex-col divide-y divide-void-gap">
                      <div className="grid grid-cols-[1fr_minmax(auto,_auto)] p-3">
                        <div className="font-bold">{temporaryAsset.name.split('.').slice(0, -1).join('.')}</div>
                        <div>
                          <Badge variant="outline" className="uppercase">
                            {temporaryAsset.type?.split('/')[1]}
                          </Badge>
                        </div>
                        <div className="text-xs text-gray-text">{temporaryAsset.name}</div>
                        <div className="text-right text-xs text-gray-text">
                          {filesize(temporaryAsset?.size, { standard: 'jedec', round: 0 })}
                        </div>
                      </div>
                      {erroredAssets[temporaryAsset.id] ? (
                        <div className="bg-status-failure p-3 text-inverted-text-color">
                          {erroredAssets[temporaryAsset.id]}
                          {/* <div className="mb-2 font-bold">Error</div>
                          {Object.keys(errors?.errors).length ? (
                            <ul>
                          ) :
                          {errors?.message && <div>{errors.message}</div>} */}
                        </div>
                      ) : (
                        <div className="flex flex-col p-3">
                          <div className="flex w-full justify-between">
                            <div className="font-bold">Uploading</div>
                            <div>{filesize(temporaryAsset?.size, { standard: 'jedec', round: 0 })}</div>
                          </div>
                          <Progress
                            value={assetsUploadProgress[temporaryAsset.id]?.progress}
                            className={cn('mt-2 h-[6px] w-full')}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <Accordion
                  type="multiple"
                  className="w-full"
                  onValueChange={(accordion) => {
                    setIsEdit(false);
                    setCurrentAccordionItems(accordion);
                  }}
                  value={currentAccordionItems}
                >
                  <AccordionItem value="information" disabled={multipleAssetSelected}>
                    <AccordionTrigger disabled={multipleAssetSelected}>Information</AccordionTrigger>
                    {!multipleAssetSelected && (
                      <AccordionContent className="m-1 flex flex-col">
                        <Form {...form}>
                          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                            <FormField
                              control={form.control}
                              name="name"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>Name</FormLabel>
                                  <FormControl>
                                    <Input
                                      {...field}
                                      isLoading={isLoading || isFetching}
                                      disabled={!isEdit || isPublicRoute}
                                      placeholder="Name"
                                      className={cn(
                                        'bg-white focus-visible:ring-offset-white dark:bg-neutral-950 dark:focus-visible:ring-offset-neutral-950',
                                        form.formState.errors.name &&
                                          '!border-red-500 dark:focus-visible:ring-red-500 ',
                                      )}
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                            <FormField
                              control={form.control}
                              name="slug"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>Slug</FormLabel>
                                  <FormControl>
                                    <Input
                                      {...field}
                                      isLoading={isLoading || isFetching}
                                      disabled
                                      placeholder="Slug"
                                      className={cn(
                                        'bg-white focus-visible:ring-offset-white dark:bg-neutral-950 dark:focus-visible:ring-offset-neutral-950',
                                        form.formState.errors.slug &&
                                          '!border-red-500 dark:focus-visible:ring-red-500 ',
                                      )}
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                            <FormField
                              control={form.control}
                              name="description"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>Description</FormLabel>
                                  <FormControl>
                                    <Textarea
                                      placeholder="Description"
                                      rows={4}
                                      {...field}
                                      disabled={!isEdit || isPublicRoute}
                                      isLoading={isLoading || isFetching}
                                      className="bg-white focus-visible:ring-offset-white dark:bg-neutral-950 dark:focus-visible:ring-offset-neutral-950"
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                            {!isPublicRoute && (
                              <div className="flex justify-end">
                                <Button
                                  type={isEdit ? 'button' : 'submit'}
                                  variant={isEdit ? 'default' : 'outline'}
                                  disabled={isFetching || isLoading}
                                  onClick={() => setIsEdit((prev) => prev === false && true)}
                                >
                                  {isLoading || isFetching ? (
                                    <Loader2 className="size-4 animate-spin stroke-neutral-700" />
                                  ) : isEdit ? (
                                    'Save Information'
                                  ) : (
                                    'Edit Information'
                                  )}
                                </Button>
                              </div>
                            )}
                          </form>
                        </Form>
                      </AccordionContent>
                    )}
                  </AccordionItem>
                  {currentSelectedType === NodeType.Assets && asset?.fileMetadata && !isPublicRoute && (
                    <FileInformationAccordion
                      disabled={multipleAssetSelected || asset.fileMetadata.length === 0}
                      asset={asset}
                    />
                  )}
                  {currentSelectedType === NodeType.Assets && asset && (
                    <AccordionItem value="metadata" disabled={multipleAssetSelected}>
                      <AccordionTrigger disabled={multipleAssetSelected}>Metadata</AccordionTrigger>
                      {!multipleAssetSelected && (
                        <AccordionContent className="m-1 flex flex-col">
                          <MetadataEditor
                            currentAccordionItems={currentAccordionItems}
                            asset={asset}
                            scrollRef={scrollRef}
                          />
                        </AccordionContent>
                      )}
                    </AccordionItem>
                  )}
                  {currentSelectedType === NodeType.Assets && asset && !isPublicRoute && (
                    <VersionsAccordion disabled={multipleAssetSelected} asset={asset} />
                  )}
                  {currentSelectedType === NodeType.Assets && !isPublicRoute && (
                    <VariantsAccordion disabled={multipleAssetSelected} asset={asset} />
                  )}
                  {currentSelectedType !== NodeType.Folders && (
                    <DestinationAccordion
                      multipleAssetSelected={multipleAssetSelected}
                      item={currentSelectedType === NodeType.Assets ? asset : album}
                      currentSelectedType={currentSelectedType}
                      currentAccordionItems={currentAccordionItems}
                    />
                  )}
                  {currentSelectedType === NodeType.Albums && album && (
                    <SharesAccordion
                      currentAccordionItems={currentAccordionItems}
                      item={album}
                      thumbnail={data[0]}
                      // item={
                      //   currentSelectedType === NodeType.Assets ? asset : currentSelectedType === NodeType.Albums ? album : folder
                      // }
                    />
                  )}

                  {currentSelectedType === NodeType.Assets && (
                    <TagsAccordion
                      asset={asset}
                      multipleAssetSelected={multipleAssetSelected}
                      currentAccordionItems={currentAccordionItems}
                    />
                  )}
                  {currentSelectedType === NodeType.Assets && asset && !isPublicRoute && (
                    <WorkflowAccordion
                      multipleAssetSelected={multipleAssetSelected}
                      item={asset}
                      currentAccordionItems={currentAccordionItems}
                    />
                  )}
                  {!isPublicRoute && (
                    <AuditLogAccordion
                      multipleAssetSelected={multipleAssetSelected}
                      selectedAssetIds={selectedAssetIds}
                      currentAccordionItems={currentAccordionItems}
                    />
                  )}
                </Accordion>
              )}
            </div>
          </>
        ) : (
          <h2 className="flex h-full items-center justify-center text-balance p-4 text-center text-lg font-semibold tracking-tight text-neutral-400">
            Nothing selected
          </h2>
        )}
      </>
    );
  };

  return (
    <div
      className={cn(
        'flex h-screen flex-col @container/inspector',
        isUploadPage && uploadState === UploadState.NotStarted && 'h-full',
        isPublicRoute && 'h-[calc(100dvh-48px)]',
      )}
    >
      {renderContent()}
    </div>
  );
};
