import { FC } from 'react';
import { AssetPreview } from '@/components/upload-dialog/asset-preview';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Trash2 } from 'lucide-react';
import type { Files } from '@/components/upload-dialog/asset-uploader';
import { filesize } from 'filesize';

type AssetListProps = {
  files: Array<Files>;
  fileNames: Array<string>;
  setFileName: (index: number, name: string) => void;
  uploadStatus: string;
  removeFile: (index: number) => void;
};

export const AssetList: FC<AssetListProps> = ({ files, fileNames, setFileName, uploadStatus, removeFile }) => {
  return (
    <ScrollArea className="h-72 max-h-[calc(100vh-370px)] rounded-md border border-neutral-200 bg-neutral-100 dark:border-neutral-800 dark:bg-neutral-950">
      <div className="grid grid-cols-1 divide-y divide-neutral-200 dark:divide-neutral-800">
        {files.map(({ name, preview, size, type }, index) => (
          <div
            className="flex gap-3 p-3 duration-200 hover:bg-neutral-200 dark:hover:bg-neutral-900"
            key={`${name}-${size}`}
          >
            <div
              className="relative h-20 min-w-[100px] rounded-md border border-neutral-200 bg-white shadow-sm dark:border-neutral-800 dark:bg-neutral-900"
              key={name}
            >
              <AssetPreview preview={preview} type={type} index={index} />
            </div>
            <div className="flex w-full flex-col gap-2">
              <Input
                value={fileNames[index]}
                className="bg-white dark:bg-neutral-900"
                onChange={(e) => setFileName(index, e.target.value)}
              />
              <p className="text-sm text-neutral-400">
                Size:
                {filesize(size, { standard: 'jedec' })}
              </p>
            </div>
            <div>
              <Button
                disabled={uploadStatus === 'uploading' || uploadStatus === 'success'}
                className="mt-px size-[38px] p-0"
                onClick={() => removeFile(index)}
              >
                <Trash2 className="size-4" />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </ScrollArea>
  );
};
