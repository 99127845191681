import Link from 'next/link';
import { FC } from 'react';
import { useRouter } from 'next/router';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { defaultPaginationSize } from '@/types/pagination';

export const Logo: FC<{ className?: string }> = ({ className }) => {
  const {
    query: { pageIndex, pageSize },
  } = useRouter();
  const { deselectAllAssets } = useAssetSelection();

  return (
    <Link
      className={className}
      onClick={deselectAllAssets}
      href={
        // We aim to retain the 'assets per page' count if accessible, guiding the user to the initial page, otherwise, direct them to '/'
        pageSize
          ? {
              pathname: '/',
              query: {
                pageIndex: 1,
                pageSize: pageSize && pageIndex ? pageSize : defaultPaginationSize,
              },
            }
          : '/'
      }
    >
      <h2 className="text-2xl font-bold leading-7 tracking-tight text-black dark:text-white">DAM</h2>
    </Link>
  );
};
