import { FC } from 'react';
import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Asset } from '@/types/asset';
import { cn } from '@/lib/utils';

export const FileInformationAccordion: FC<{ asset: Asset; disabled: boolean }> = ({ asset, disabled }) => (
  <AccordionItem value="file-information" disabled={disabled}>
    <AccordionTrigger disabled={disabled}>File Information</AccordionTrigger>
    {!disabled && (
      <AccordionContent className="m-1 flex flex-col">
        <div className="divide-y divide-panel-background">
          {asset &&
            Object.entries(asset?.fileMetadata as Record<string, { description: string; value: string }>).map(
              ([key, entry]) => (
                <div key={key} className="flex flex-col py-2">
                  <div className="font-bold">{entry.description}</div>
                  <div
                    {...(entry?.value?.length > 100 ? { title: entry.value } : {})}
                    className={cn(entry.value?.length > 100 && 'line-clamp-3')}
                  >
                    {entry.value}
                  </div>
                </div>
              ),
            )}
        </div>
      </AccordionContent>
    )}
  </AccordionItem>
);
