import { useCallback, useEffect, useState } from 'react';
import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { SortOrderOptions, SortOrderValues } from '@/types/sort';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { useDestinationsList } from '@/hooks/destinations/useDestinations';
import { DestinationSwitch } from '@/components/inspector/destination-switch';
import { setDestination } from '@/services/destination.service';
import { ToastAction } from '@/components/ui/toast';
import { TrafficCone } from 'lucide-react';
import { useAuth0 } from '@auth0/auth0-react';
import { Skeleton } from '@/components/ui/skeleton';
import { useToast } from '@/components/ui/use-toast';
import { Folder } from '@/types/folder';
import { Asset } from '@/types/asset';
import { Album } from '@/types/album';
import { cn } from '@/lib/utils';
import debounce from 'lodash/debounce';

export const DestinationAccordion = ({
  multipleAssetSelected,
  item,
  currentAccordionItems,
  currentSelectedType,
}: {
  multipleAssetSelected: boolean;
  item?: Asset | Folder | Album;
  currentAccordionItems: Array<string>;
  currentSelectedType?: string;
}) => {
  const queryClient = useQueryClient();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [enabledDestinations, setEnabledDestinations] = useState<Array<string>>([]);

  const { isAuthenticated } = useAuth0();
  const { toast } = useToast();
  const { destinationListIsFetching, destinationList, refetchDestinationList } = useDestinationsList(
    {
      pagination: { limit: 0, offset: 0 },
      sort: { order: SortOrderOptions.ASC, value: SortOrderValues.CREATED_AT },
      queryString: '',
    },
    {
      enabled: currentAccordionItems?.includes('destination') && isAuthenticated,
    },
  );
  const { destinations, pagination: paginationInfo } = destinationList;

  const setItemDestinationWithAuth = useAuthenticatedQueryFn(setDestination);

  const setItemDestinationMutation = useMutation({
    mutationFn: setItemDestinationWithAuth,
    onSuccess: async () => {
      const AssetQueryKeyPrefix = currentSelectedType === 'assets' ? 'asset' : 'album';

      await queryClient.invalidateQueries({ queryKey: [AssetQueryKeyPrefix] });

      toast({
        title: 'Destination updated',
        description: (
          <>
            The destination for <span className="font-bold">{item?.name}</span> has been successfully updated.
          </>
        ),
      });
    },
    onError: (err) => {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: err.message ?? 'Unable to update destination',
        action: <ToastAction altText="Close">Close</ToastAction>,
      });
    },
  });

  useEffect(() => {
    if (currentAccordionItems?.includes('destination')) {
      void refetchDestinationList();
    }
    if (item?.destinations) {
      setEnabledDestinations(item?.destinations);
    }
  }, [item?.destinations]);

  const debouncedSetItemDestinationMutation = useCallback(
    debounce((updatedDestinations) => {
      setItemDestinationMutation.mutate({
        destinationIds: updatedDestinations,
        itemId: item?.id,
        type: currentSelectedType,
      });
    }, 500),
    [item?.id],
  );

  const handleDestinationToggle = (id: string, enabled: boolean) => {
    setEnabledDestinations((prevEnabledDestinations) => {
      const updatedDestinations = enabled
        ? Array.from(new Set([...prevEnabledDestinations, id]))
        : prevEnabledDestinations.filter((destinationId) => destinationId !== id);

      debouncedSetItemDestinationMutation(updatedDestinations);

      return updatedDestinations;
    });
  };

  return (
    <AccordionItem value="destination" disabled={multipleAssetSelected}>
      <AccordionTrigger disabled={multipleAssetSelected}>Destinations</AccordionTrigger>
      {destinationListIsFetching ? (
        <AccordionContent className="m-1 flex flex-col">
          <div className="flex flex-col gap-2.5 @[18rem]/inspector:px-2">
            {Array.from(Array(2)).map((_, index) => (
              <div className="flex w-full gap-x-3" key={index}>
                <Skeleton className="max-h-5 min-h-5 w-9 min-w-9 max-w-9 rounded-full bg-neutral-200 dark:bg-neutral-800" />
                <Skeleton className={cn('h-5 bg-neutral-200 dark:bg-neutral-800', index === 0 ? 'w-3/6' : 'w-2/5')} />
              </div>
            ))}
          </div>
        </AccordionContent>
      ) : destinations && destinations.length > 0 ? (
        <AccordionContent className="flex flex-col gap-2.5 @[11rem]/inspector:m-1 @[18rem]/inspector:px-2">
          {destinations?.map(({ id, name }: { id: string; name: string }) => {
            return (
              <DestinationSwitch
                key={id}
                id={id}
                handleDestinationToggle={handleDestinationToggle}
                checked={Boolean(item?.destinations?.find((destinationId) => destinationId === id))}
              >
                {name}
              </DestinationSwitch>
            );
          })}
        </AccordionContent>
      ) : (
        <AccordionContent className="py-8 text-center text-neutral-600">
          <TrafficCone className="mx-auto mb-2" size={28} strokeWidth={1.5} />
          No destinations
        </AccordionContent>
      )}
    </AccordionItem>
  );
};
