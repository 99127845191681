import { z } from 'zod';

export const destinationSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  name: z.string(),
  slug: z.string(),
  description: z.string(),
  createdBy: z.object({
    id: z.string(),
    name: z.string(),
  }),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type Destination = z.infer<typeof destinationSchema>;

export const getDestinationsSchema = z.array(destinationSchema);

export type GetDestination = z.infer<typeof getDestinationsSchema>;

export const destinationArraySchema = z.array(z.string());

export type setDestinationReturn = z.infer<typeof destinationArraySchema>;
