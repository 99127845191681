import { Dispatch, ReactNode, SetStateAction, useCallback } from 'react';
import { cn } from '@/lib/utils';
import { uniq, sortBy } from 'lodash';
import { useTree } from '@/hooks/data/tree/useTree';
import { NodeType, TreeRecursiveChildrenNode } from '@/types/tree';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { Album } from '@/components/sidebar/tree/album';
import { Folder } from '@/components/sidebar/tree/folder';
import { useTreeStore } from '@/hooks/data/tree/useTreeStore';
import { useSearch } from '@/context/SearchContext';
import { useRouter } from 'next/router';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { useUploadStore } from '@/hooks/data/inspector/useUploadStore';
import { PaginationState } from '@/types/pagination';

type TreeProps = {
  className?: ReactNode;
  openIds: Array<string>;
  setOpenIds: Dispatch<SetStateAction<Array<string>>>;
  tree: Array<TreeRecursiveChildrenNode>;
  reusable: boolean;
  handleOnSortChange: (value: string) => void;
  setPagination?: Dispatch<SetStateAction<PaginationState>>;
};

export function Tree({
  className,
  handleOnSortChange,
  setPagination,
  openIds,
  setOpenIds,
  tree,
  reusable = false,
}: TreeProps) {
  const toggleNodeHandler = (clickedId: string) => {
    const isOpen = openIds.includes(clickedId);
    const updatedIds = uniq(isOpen ? openIds.filter((id) => id !== clickedId) : [...openIds, clickedId]);
    setOpenIds(updatedIds);
  };

  return (
    <ul className={cn('tree group/tree mt-px flex flex-col', className)} data-testid="tree">
      <Branch
        tree={tree}
        openIds={openIds}
        toggleNodeHandler={toggleNodeHandler}
        parentId={undefined}
        depth={0}
        reusable={reusable}
        handleOnSortChange={handleOnSortChange}
        setPagination={setPagination}
      />
    </ul>
  );
}

type BranchProps = {
  tree: Array<TreeRecursiveChildrenNode>;
  openIds: Array<string>;
  toggleNodeHandler: (id: string) => void;
  parentId?: string;
  depth: number;
  reusable: boolean;
  handleOnSortChange: (value: string) => void;
  setPagination?: Dispatch<SetStateAction<PaginationState>>;
};

export function Branch({
  tree,
  openIds,
  toggleNodeHandler,
  parentId,
  depth,
  reusable,
  handleOnSortChange,
  setPagination,
}: BranchProps) {
  const { push, query } = useRouter();
  const { deselectAllAssets } = useAssetSelection();
  const { setCurrentSelectedType, setSelectedNodes } = useTree();
  const { setSearchInsideFolder } = useSearch();
  const { browseRedirection } = useCurrentPage();

  const { setUploadFolderPath, clearAllFiles } = useUploadStore();
  const findNodePath = useTreeStore((state) => state.findNodePath);
  const setSelectedFolder = useTreeStore((state) => state.setSelectedFolder);
  const setSelectedAlbum = useTreeStore((state) => state.setSelectedAlbum);
  const setReusableTreeSelectedFolder = useTreeStore((state) => state.setReusableTreeSelectedFolder);
  const sortedTree = sortBy(tree, (treeItem) => treeItem.node.name.toLowerCase());

  const handleSelectFolder = useCallback(
    (treeBranch: TreeRecursiveChildrenNode) => {
      const { id, node } = treeBranch;
      const { name, id: folderId } = node;

      if (reusable) {
        const pathArray = findNodePath(id, 'name');
        const path = pathArray?.join('/');
        setUploadFolderPath(`/${path}`);
        setReusableTreeSelectedFolder(folderId);
        clearAllFiles();
      } else {
        deselectAllAssets();
        setSelectedNodes([{ id, name }]);
        setCurrentSelectedType(NodeType.Folders);
        setSearchInsideFolder(false);
        setSelectedAlbum(undefined);
        setSelectedFolder(id);
        if (setPagination) {
          setPagination((prev) => ({
            ...prev,
            pageIndex: 0,
          }));
        }
        const pathArray = findNodePath(id, 'slug');
        browseRedirection(pathArray);
      }
    },
    [
      reusable,
      setUploadFolderPath,
      deselectAllAssets,
      setSelectedNodes,
      setCurrentSelectedType,
      setSearchInsideFolder,
      setSelectedAlbum,
      setSelectedFolder,
      browseRedirection,
      push,
      query,
    ],
  );

  return sortedTree?.map((treeBranch) => {
    const { id, type } = treeBranch;

    switch (type) {
      case 'folder':
        return (
          <Folder
            key={id}
            treeBranch={treeBranch}
            openIds={openIds}
            toggleNodeHandler={toggleNodeHandler}
            parentId={parentId}
            depth={depth}
            onSelectFolder={() => handleSelectFolder(treeBranch)}
            reusable={reusable}
            handleOnSortChange={handleOnSortChange}
            setPagination={setPagination}
          />
        );
      case 'album':
        return reusable ? null : (
          <Album
            key={id}
            treeBranch={treeBranch}
            parentId={parentId}
            depth={depth}
            handleOnSortChange={handleOnSortChange}
            setPagination={setPagination}
          />
        );
      default:
        return null;
    }
  });
}
