import { Dispatch, SetStateAction, PropsWithChildren, createContext, useContext, useState, useEffect } from 'react';
import { AssetSelectionTypes } from '@/context/AssetSelectionContext';
import { AssetIdNameTypes } from '@/types/asset';
import { useRouter } from 'next/router';
import { NodeType } from '@/types/tree';
import { uniq } from 'lodash';

export interface TreeContextProps {
  firstTimeLoaded: boolean;
  setFirstTimeLoaded: Dispatch<SetStateAction<boolean>>;
  sidebarTreeOpenIds: Array<string>;
  setSidebarTreeOpenIds: Dispatch<SetStateAction<Array<string>>>;
  reusableTreeOpenIds: Array<string>;
  setReusableTreeOpenIds: Dispatch<SetStateAction<Array<string>>>;
  updatedIds: Array<string>;
  setUpdatedIds: Dispatch<SetStateAction<Array<string>>>;
  selectedNodes: Array<AssetIdNameTypes>;
  setSelectedNodes: Dispatch<SetStateAction<Array<AssetIdNameTypes>>>;
  toggleNodeSelection: ({ id, name, assets, index, metaKey, shiftKey, ctrlKey }: AssetSelectionTypes) => void;
  currentSelectedType: string | undefined;
  setCurrentSelectedType: Dispatch<SetStateAction<NodeType | undefined>>;
  contextNodeSelection: ({ id, name }: AssetIdNameTypes) => void;
  includeSubFolderAssets: boolean;
  toggleSubFolders: () => void;
}

export const TreeContext = createContext<TreeContextProps>({
  firstTimeLoaded: true,
  setFirstTimeLoaded: () => {},
  sidebarTreeOpenIds: [],
  setSidebarTreeOpenIds: () => {},
  reusableTreeOpenIds: [],
  setReusableTreeOpenIds: () => {},
  updatedIds: [],
  setUpdatedIds: () => {},
  selectedNodes: [],
  setSelectedNodes: () => {},
  toggleNodeSelection: () => {},
  currentSelectedType: undefined,
  setCurrentSelectedType: () => {},
  contextNodeSelection: () => {},
  includeSubFolderAssets: false,
  toggleSubFolders: () => {},
});

export function TreeContextProvider({ children }: PropsWithChildren) {
  const { query } = useRouter();

  const [sidebarTreeOpenIds, setSidebarTreeOpenIds] = useState<Array<string>>([]);
  const [reusableTreeOpenIds, setReusableTreeOpenIds] = useState<Array<string>>([]);
  const [firstTimeLoaded, setFirstTimeLoaded] = useState(true);
  const [updatedIds, setUpdatedIds] = useState<Array<string>>([]);

  const [selectedNodes, setSelectedNodes] = useState<Array<AssetIdNameTypes>>([]);
  const [currentSelectedType, setCurrentSelectedType] = useState<NodeType | undefined>(
    query.folder ? NodeType.Folders : query.album ? NodeType.Albums : NodeType.Assets,
  );
  const [lastSelectedIndex, setLastSelectedIndex] = useState(-1);
  // Setting the folder as a selected type for the initial load
  useEffect(() => {
    setCurrentSelectedType(NodeType.Folders);
  }, []);

  const [includeSubFolderAssets, setIncludeSubFolderAssets] = useState(false);
  const toggleSubFolders = () => {
    if (currentSelectedType === NodeType.Folders) {
      setIncludeSubFolderAssets((prev) => !prev);
    }
  };

  const contextNodeSelection = ({ id, name }: AssetIdNameTypes) => {
    if (!selectedNodes.some(({ id: nodeId }) => nodeId === id)) {
      setSelectedNodes([{ id, name }]);
    }
  };

  const toggleNodeSelection = ({ id, name, assets, index, metaKey, shiftKey, ctrlKey }: AssetSelectionTypes) => {
    let newSelectedNodes: Array<AssetIdNameTypes>;
    const newLastSelectedIndex = index;
    if (currentSelectedType !== NodeType.Assets) {
      selectedNodes.splice(0);
    }
    if (!metaKey && !shiftKey && !ctrlKey) {
      if (selectedNodes.some(({ id: nodeId }) => nodeId === id)) {
        newSelectedNodes = selectedNodes.filter(({ id: nodeId }) => nodeId !== id);
      } else {
        newSelectedNodes = [{ id, name }];
      }
    } else if (shiftKey) {
      if (lastSelectedIndex >= index) {
        const selectedOnes = assets?.slice(index, lastSelectedIndex).map(({ nodeId: id, name }) => ({ id, name }));
        newSelectedNodes = selectedNodes.concat(selectedOnes);
      } else {
        const selectedOnes = assets
          .slice(lastSelectedIndex + 1, index + 1)
          .map(({ nodeId: id, name }) => ({ id, name }));
        newSelectedNodes = selectedNodes.concat(selectedOnes);
      }
    } else if (metaKey || ctrlKey) {
      const foundIndex = selectedNodes.findIndex((f) => f.id === id);
      // If found remove it to unselect it.
      if (foundIndex >= 0) {
        newSelectedNodes = [...selectedNodes.slice(0, foundIndex), ...selectedNodes.slice(foundIndex + 1)];
      } else {
        newSelectedNodes = [...selectedNodes, { id, name }];
      }
    }
    const finalList = assets
      ? assets
          .filter((f) => uniq(newSelectedNodes).find((a) => a.id === f.nodeId))
          .map(({ nodeId: id, name }) => ({ id, name }))
      : [];
    setSelectedNodes(finalList);
    setLastSelectedIndex(newLastSelectedIndex);
  };

  return (
    <TreeContext.Provider
      value={{
        firstTimeLoaded,
        setFirstTimeLoaded,
        sidebarTreeOpenIds,
        setSidebarTreeOpenIds,
        reusableTreeOpenIds,
        setReusableTreeOpenIds,
        selectedNodes,
        setSelectedNodes,
        toggleNodeSelection,
        currentSelectedType,
        setCurrentSelectedType,
        contextNodeSelection,
        updatedIds,
        setUpdatedIds,
        includeSubFolderAssets,
        toggleSubFolders,
      }}
    >
      {children}
    </TreeContext.Provider>
  );
}

export function useTreeContext() {
  return useContext(TreeContext);
}
