import { cn } from '@/lib/utils';
import { TableCell, TableRow } from '@/components/ui/table';
import { flexRender, Row } from '@tanstack/react-table';
import { Asset } from '@/types/asset';
import { Badge } from '@/components/ui/badge';

export const DraggedItem = <TData extends Asset>({
  isListView,
  rows,
}: {
  isListView: boolean;
  rows?: Array<Row<TData>>;
}) => {
  return (
    <>
      {isListView ? (
        <>
          {rows &&
            rows.map((row, index) => (
              <TableRow
                key={`list-${row.id}`}
                className="absolute"
                style={{
                  transform: `rotate(${(7.5 / rows.length) * index}deg)`,
                  zIndex: rows.length - index,
                }}
              >
                {row
                  .getVisibleCells()
                  .map(
                    (cell: {
                      id: string;
                      column: { columnDef: any; getSize: () => number };
                      getContext: () => any;
                    }) => {
                      return (
                        <TableCell
                          key={cell.id}
                          style={{ width: cell.column.getSize() !== 150 ? cell.column.getSize() : 'auto' }}
                          className={cn('select-none', cell.column.columnDef.meta?.cellClassName)}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      );
                    },
                  )}
              </TableRow>
            ))}
        </>
      ) : (
        <>
          {rows &&
            rows.map((row, index) => (
              <div
                key={`gallery-${row.id}`}
                className="absolute size-full bg-light-ui-color p-3 transition-colors duration-150"
                style={{
                  transform: `rotate(${(30 / rows.length) * index}deg)`,
                  zIndex: rows.length - index,
                }}
              >
                {row
                  .getVisibleCells()
                  .map(
                    (cell: { id: string; column: { columnDef: any; getSize: () => number }; getContext: () => any }) =>
                      flexRender(cell.column.columnDef.cell, cell.getContext()),
                  )}
              </div>
            ))}
          <div className="absolute bottom-[-0.75rem] right-[-0.75rem] z-[10]">
            <Badge variant="destructive">{rows && rows.length !== 0 ? rows.length : 1}</Badge>
          </div>
        </>
      )}
    </>
  );
};
