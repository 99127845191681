import { useEffect, useState } from 'react';
import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { SortOrderOptions, SortOrderValues } from '@/types/sort';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { findKey, has, get, isEmpty } from 'lodash';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { useWorkflowList } from '@/hooks/workflow/useWorkflow';
import { setWorkflow } from '@/services/workflow.service';
import { ToastAction } from '@/components/ui/toast';
import { TrafficCone } from 'lucide-react';
import { useToast } from '@/components/ui/use-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { Asset } from '@/types/asset';
import { cn } from '@/lib/utils';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { WorkflowStatuses } from '@/types/workflow';

export const WorkflowAccordion = ({
  multipleAssetSelected,
  item,
  currentAccordionItems,
}: {
  multipleAssetSelected: boolean;
  item?: Asset;
  currentAccordionItems: Array<string>;
}) => {
  const queryClient = useQueryClient();

  const [selectedWorkflow, setSelectedWorkflow] = useState({});

  const { isAuthenticated } = useAuth0();
  const { toast } = useToast();

  const { workflowList, workflowListIsFetching } = useWorkflowList(
    {
      pagination: {
        offset: null,
        limit: null,
      },
      sort: {
        value: SortOrderValues.CREATED_AT,
        order: SortOrderOptions.ASC,
      },
      queryString: '',
    },
    { enabled: currentAccordionItems?.includes('workflow') && isAuthenticated },
  );
  const { workflows } = workflowList;

  useEffect(() => {
    if (item?.workflows) {
      setSelectedWorkflow(item?.workflows);
    }
  }, [item?.workflows]);

  const setWorkflowWithAuth = useAuthenticatedQueryFn(setWorkflow);
  const setWorkflowMutation = useMutation({
    mutationFn: setWorkflowWithAuth,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['asset'] });

      toast({
        title: 'Workflow updated',
        description: (
          <>
            The workflow for <span className="font-bold">{item?.name}</span> has been successfully updated.
          </>
        ),
      });
    },
    onError: (err) => {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: err.message ?? 'Unable to update workflow',
        action: <ToastAction altText="Close">Close</ToastAction>,
      });
    },
  });

  const handleChange = (selectedValue: string) => {
    const filteredWorkflow = workflows.filter((workflow) =>
      Object.values(workflow.statuses).find((status) => status.id === selectedValue),
    );
    const statusSlug = findKey(filteredWorkflow[0].statuses, { id: selectedValue });
    setWorkflowMutation.mutate({
      asset_id: item?.id,
      workflows: { [filteredWorkflow[0].slug]: statusSlug },
    });
  };

  return (
    <AccordionItem value="workflow" disabled={multipleAssetSelected}>
      <AccordionTrigger disabled={multipleAssetSelected}>Workflows</AccordionTrigger>
      {workflows && workflows.length > 0 ? ( // TODO it should be with && workflows.statuses.length > 0 and the types should be adjusted
        workflows.map(
          ({ slug, statuses }) =>
            !isEmpty(statuses) && (
              <AccordionContent
                key={slug}
                className="flex flex-col gap-2.5 @[11rem]/inspector:m-1 @[18rem]/inspector:px-2"
              >
                <span className="capitalize">{slug}:</span>{' '}
                <Select onValueChange={handleChange} disabled={workflowListIsFetching}>
                  <SelectTrigger>
                    <SelectValue
                      placeholder={
                        selectedWorkflow && has(selectedWorkflow, slug)
                          ? get<WorkflowStatuses>(statuses, get(selectedWorkflow, slug)).name
                          : 'Select an option'
                      }
                    />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.values(statuses).map(({ id, name, color }) => (
                      <SelectItem key={id} value={id}>
                        <div className="flex items-center justify-between">
                          <div style={{ backgroundColor: color }} className={cn('ml-0.5 mr-2.5 size-2 rounded-full')} />
                          <div>{name}</div>
                        </div>
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </AccordionContent>
            ),
        )
      ) : (
        <AccordionContent className="py-8 text-center text-slate-600">
          <TrafficCone className="mx-auto mb-2" size={28} strokeWidth={1.5} />
          No workflow
        </AccordionContent>
      )}
    </AccordionItem>
  );
};
