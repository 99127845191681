import { FC, PropsWithChildren, forwardRef } from 'react';
import { Switch } from '@/components/ui/switch';

type MetadataSwitchProps = {
  'id': string;
  'name': string;
  'placeholder': string;
  'value': boolean;
  'className'?: string;
  'onChange': (value: boolean) => void;
  'disabled': boolean;
  'data-cy'?: string;
  'onClick'?: (...args: Array<any>) => void;
};

export const MetadataSwitch: FC<PropsWithChildren<MetadataSwitchProps>> = forwardRef<
  HTMLDivElement,
  MetadataSwitchProps
>(({ id, placeholder, name, ...props }, ref) => (
  <div className={props?.className} ref={ref}>
    <Switch
      id={name}
      disabled={props?.disabled}
      checked={props?.value}
      onCheckedChange={props?.onChange}
      onClick={props?.onClick}
      {...(props['data-cy'] ? { 'data-cy': props['data-cy'] } : {})}
    />
  </div>
));

MetadataSwitch.displayName = 'MetadataSwitch';
