import { useEffect, useState, FC } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { BackendError, useFormErrorHandler } from '@/hooks/useFormErrorHandler';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { FormProvider, useForm } from 'react-hook-form';
import { error as errorLog } from '@/utilities/log';
import { updateDestination } from '@/services/destination.service';
import { useDestination } from '@/hooks/destinations/useDestinations';
import { TenantUserRole } from '@/types/tenantUserRole';
import { Loader2, Save } from 'lucide-react';
import { renderField } from '@/components/inspector/entity-fields/form/field-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAccount } from '@/hooks/data/account/useAccount';
import { capitalize } from 'lodash';
import { Separator } from '@/components/ui/separator';
import { useToast } from '@/components/ui/use-toast';
import { Skeleton } from '@/components/ui/skeleton';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { z } from 'zod';

type DestinationFieldData = {
  id: string;
  name?: string;
  slug?: string;
  description?: string;
};

export const DestinationFields: FC<{ destinationId: string }> = ({ destinationId }) => {
  const queryClient = useQueryClient();

  const { toast } = useToast();
  const { destination: fieldData, destinationIsFetching } = useDestination(destinationId) as {
    destination: DestinationFieldData;
    destinationIsFetching: boolean;
  };
  const {
    profile: { userRole },
  } = useAccount();

  const [currentAccordionItems, setCurrentAccordionItems] = useState<Array<string>>(['destination']);

  // Create a dynamic form schema based on the fieldData
  const createFormSchema = (data: DestinationFieldData) => {
    const schema: Record<string, z.ZodType<any>> = {};

    Object.keys(data).forEach((key) => {
      switch (key) {
        case 'name':
          schema[key] = z.string().min(1, { message: `${capitalize(key)} field is required.` });
          break;
        case 'description':
          schema[key] = z.string({ required_error: 'The description field must be a string.' }).min(1, {
            message: 'Description field is required.',
          });
          break;
        case 'slug':
          schema[key] = z.string().min(1, { message: `${capitalize(key)} field is required.` });
          break;
        default:
          break;
      }
    });

    return z.object(schema);
  };

  const formSchema = fieldData ? createFormSchema(fieldData) : z.object({});

  // Dynamic default values based on the fieldData
  const createDefaultValues = (data: DestinationFieldData) => {
    const defaultValues: Partial<Record<keyof DestinationFieldData, any>> = {};

    if (data) {
      Object.keys(data).forEach((key) => {
        defaultValues[key as keyof DestinationFieldData] = data[key as keyof DestinationFieldData];
      });

      return defaultValues;
    }
  };

  const form = useForm({
    resolver: zodResolver(fieldData && createFormSchema(fieldData)),
    defaultValues: fieldData && createDefaultValues(fieldData),
  });

  const { handleError } = useFormErrorHandler(form.setError, form.getValues);

  // Reset form values when fieldData changes
  useEffect(() => {
    form.reset(createDefaultValues(fieldData));
  }, [fieldData, form.reset]);

  const updateDestinationWithAuth = useAuthenticatedQueryFn(updateDestination);

  const updateDestinationMutation = useMutation({
    mutationFn: updateDestinationWithAuth,
    onSuccess: async () => {
      toast({
        title: 'Destination Saved',
        description: 'Destination fields have been saved successfully.',
      });

      await queryClient.invalidateQueries({ queryKey: ['destinationList'] });
    },
    onError: (err: BackendError) => {
      handleError(err);
      errorLog(err);
    },
  });

  const isLoading = updateDestinationMutation.status === 'pending';
  const disabled = destinationIsFetching || isLoading || userRole !== TenantUserRole.Owner;

  function onSubmit(values: z.infer<ReturnType<typeof createFormSchema>>) {
    if (disabled) {
      return;
    }

    updateDestinationMutation.mutate({
      id: fieldData.id,
      body: {
        ...values,
      },
    });
  }

  return (
    <Accordion
      onValueChange={(accordion) => {
        setCurrentAccordionItems(accordion);
      }}
      value={currentAccordionItems}
      type="multiple"
      className="w-full"
      defaultValue={['destination']}
    >
      <AccordionItem className="border-0" value={'destination'}>
        <AccordionTrigger className="h-[48px] border-y border-b-neutral-300 border-t-neutral-100 bg-neutral-200 p-3 hover:no-underline @[18rem]/inspector:py-3 dark:border-b-black dark:border-t-[#2D2D2D] dark:bg-[#222222]">
          Destination
        </AccordionTrigger>
        <AccordionContent className="flex flex-col bg-neutral-200 p-3 dark:bg-[#1C1C1C]">
          {destinationIsFetching ? (
            <div className="flex flex-col space-y-6">
              {Array.from(Array(3)).map((_, index) => (
                <>
                  <div key={`skeleton-${index}`} className="flex flex-col space-y-3">
                    <Skeleton className={cn('h-5 w-1/3', { 'w-2/5': index === 0 })} />
                    <Skeleton className="h-8 w-full" />
                    <Skeleton className={cn('h-5 w-1/3', { 'w-2/3': index === 1 })} />
                  </div>
                  {index !== 2 && <Separator className="block" />}
                </>
              ))}
            </div>
          ) : (
            <FormProvider {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="relative flex flex-col space-y-6 @[30rem]/inspector:space-y-2 @[41rem]/inspector:mx-auto @[41rem]/inspector:w-[600px]"
              >
                {Object.keys(fieldData).map((key) => {
                  const validation = (formSchema.shape as Record<string, z.ZodType<any>>)[key];

                  switch (key) {
                    case 'name':
                      return renderField(
                        form,
                        {
                          'name': 'Destination Name',
                          'type': 'string',
                          'slug': key,
                          'disabled': disabled,
                          validation,
                          'data-cy': 'destination-name-field',
                        },
                        'block @[24rem]/inspector:hidden',
                      );
                    case 'slug':
                      return renderField(
                        form,
                        {
                          'name': 'Destination Slug',
                          'type': 'string',
                          'slug': key,
                          'disabled': disabled,
                          validation,
                          'data-cy': 'destination-slug-field',
                        },
                        'block @[24rem]/inspector:hidden',
                      );
                    case 'description':
                      return renderField(
                        form,
                        {
                          'name': 'Destination Description',
                          'type': 'text',
                          'slug': key,
                          'disabled': disabled,
                          validation,
                          'data-cy': 'destination-description-field',
                        },
                        'block @[24rem]/inspector:hidden',
                      );
                    default:
                      return null;
                  }
                })}

                <Button
                  disabled={disabled}
                  type="submit"
                  variant="default"
                  className="w-full justify-center @[21rem]/inspector:w-auto"
                  data-cy="destination-save-button"
                >
                  {isLoading ? (
                    <>
                      <Loader2 className="mr-2 size-4 animate-spin" />
                      Saving...
                    </>
                  ) : (
                    <>
                      <Save className="mr-2 size-4" />
                      Save
                    </>
                  )}
                </Button>
              </form>
            </FormProvider>
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
